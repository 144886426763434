import {IonButton, IonChip, IonCol, IonIcon, IonItem, IonLabel, IonRow} from "@ionic/react";
import React from "react";
import {ComputationResult} from "../../types/types";
import {useTranslation} from "react-i18next";
import {downloadOutline} from "ionicons/icons";


type RowProps = {
    computationResult: ComputationResult;
};

const Row = ({computationResult} : RowProps) => {
    const { i18n } = useTranslation(['translation', 'common']);
    return (
        <IonRow className="ion-row-custom">
            <IonCol className="ion-col-custom" size="4">
                <IonItem lines="none">
                    {i18n.t('common:intlDateTime', {
                        val: Date.parse(computationResult.generation_date),
                        formatParams: {
                            val: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
                        }
                    })}
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="2" >
                <IonItem lines="none">
                    <IonChip color="tertiary" outline={false} disabled={false}>
                        <IonLabel>{computationResult.product_code}</IonLabel>
                    </IonChip>
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="2" >
                <IonItem lines="none">
                    {i18n.t('common:intlDateTime', {
                        val: Date.parse(computationResult.horodate_max_coef_verifie),
                        formatParams: {
                            val: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
                        }
                    })}
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="2" >
                <IonItem lines="none">
                    {i18n.t('common:intlDateTime', {
                        val: Date.parse(computationResult.horodate_max_coef_anticipe),
                        formatParams: {
                            val: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
                        }
                    })}
                </IonItem>
            </IonCol>

            <IonCol className="ion-col-custom" size="2" >
                <IonItem lines="none" >
                    <IonButton slot="start" fill="clear" href={computationResult.resultat}>
                        <IonIcon slot="icon-only" icon={downloadOutline}/>
                    </IonButton>
                </IonItem>
            </IonCol>
        </IonRow>
    );
};

export default Row;