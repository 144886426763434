import {createSlice, isAnyOf, PayloadAction} from '@reduxjs/toolkit'
import {AuthState} from "../../types/types";
import {authApi} from "../../services/auth/auth-api";
import jwt_decode, { JwtPayload } from 'jwt-decode';

// Define the initial state using that type
const initialState: AuthState = {
    access: "",
    refresh: "",
    is_active: false,
    user_email: "",
    user_id: undefined,
    expire: new Date().getTime(),
}

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        removeAccessToken: () => {
            return initialState
        }
    },
    // On ajoute un extra reducer écoutant le résultat de la requête de login - si c'est bon on met à jour le token
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(authApi.endpoints.login.matchFulfilled, authApi.endpoints.register.matchFulfilled), (state, action:PayloadAction<AuthState>) => {
            let decoded = jwt_decode<JwtPayload  & {user_email: string}>(action.payload.access || '', ) || null;

            if(decoded && decoded.exp && decoded.user_email){
                return {...state, ...action.payload, user_email: decoded.user_email, expire: decoded.exp * 1000}
            }
        });
    },
});

export const { removeAccessToken } = authSlice.actions

// Other code such as selectors can use the imported `RootState` type

export const authReducer = authSlice.reducer;