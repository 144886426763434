import {IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import React from "react";
import {useTranslation} from "react-i18next";
import SimpleGraphicDateSelector from "../../components/home/SimpleGraphicDateSelector";
import {useGetTemperatureMeteoFranceQuery, useGetTemperatureOpenDataEnedisQuery} from "../../services/profilage-api";


const OpenDataNuclear: React.FC = () => {
    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'temperatures' });

    const GRAPHICS = [
        {
            label:t("title"),
            unique_key: "temperatures",
            fc_queries:[useGetTemperatureMeteoFranceQuery, useGetTemperatureOpenDataEnedisQuery, useGetTemperatureOpenDataEnedisQuery],
            custom_filter:[{}, {'type': 'REEL'}, {'type': 'NORMALE'}],
            data_names:['valeur', 'valeur', 'valeur'],
            line_type: 'monotone',
            names:[t('temperature_prev'), t('temperature_real'), t('temperature_normal')],
            connect_nulls:false,
            active_multi_date_selector: [false, false, false],
            colors:['#8884d8', '#82ca9d', '#FF8317']
        },
    ]

    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        {GRAPHICS.map((props, idx)=>{
                            return <SimpleGraphicDateSelector key={idx} {...props}/>
                        })}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>

    );
};


export default OpenDataNuclear;
