import {IonContent} from "@ionic/react";
import Infos from "../components/home/Infos";


const Home: React.FC = () => {

    return (
        <IonContent fullscreen>
            <Infos/>
        </IonContent>

    );
};

export default Home;
