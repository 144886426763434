import {IonChip, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonProgressBar, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {chevronDownOutline, chevronUpOutline} from "ionicons/icons";

import {useGetRunInputsQuery} from "../../services/profiles-api";
import {useState} from "react";
import Pagination from "../utils/pagination";
import Row from "./Row";
import '../utils/table.css'

const PAGE_SIZE = 10

const RunEngineTable = () => {

    const {t} = useTranslation('translation', {keyPrefix: 'cdc_engine'});
    const [sortDirection, setSortDirection] = useState(false)
    const [page, setPage] = useState(1)
    let params: Record<any, any>= {
        'ordering': sortDirection ? 'creation_datetime' : '-creation_datetime',
        'page': page,
        'page_size': PAGE_SIZE,
    };

    const {data: listRunInputs, isLoading, isFetching} = useGetRunInputsQuery(params, {
        pollingInterval: 10000,
    })
    const totalPages = Math.max(Math.ceil(listRunInputs ? (listRunInputs.count) / PAGE_SIZE : 1), 1)

    return (
        <IonGrid>
            <IonRow class="row-header">
                <IonCol className="ion-col-custom" size="1">
                    <IonItem className="item-header" lines="none" >
                        <IonLabel class="ion-text-wrap"><strong>{t('demand_id')}</strong></IonLabel>
                    </IonItem>
                </IonCol>
                <IonCol className="ion-col-custom" size="2">
                    <IonItem className="item-header" button={true} lines="none" onClick={() => setSortDirection(!sortDirection)}>
                        <IonLabel class="ion-text-wrap"><strong>{t('creation_datetime')}</strong></IonLabel>
                        <IonIcon slot="end" icon={sortDirection ? chevronDownOutline : chevronUpOutline}/>
                    </IonItem>
                </IonCol>
                <IonCol className="ion-col-custom" size="1">
                    <IonItem className="item-header" lines="none">
                        <IonLabel class="ion-text-wrap"><strong>{t('demand_type')}</strong></IonLabel>
                    </IonItem>
                </IonCol>
                <IonCol className="ion-col-custom" size="1">
                    <IonItem className="item-header" lines="none">
                        <IonLabel class="ion-text-wrap"><strong>{t('input_type')}</strong></IonLabel>
                    </IonItem>
                </IonCol>
                <IonCol className="ion-col-custom" size="1.5">
                    <IonItem className="item-header" lines="none">
                        <IonLabel class="ion-text-wrap"><strong>{t('input_file')}</strong></IonLabel>
                    </IonItem>
                </IonCol>
                <IonCol className="ion-col-custom" size="3.25">
                    <IonItem className="item-header" lines="none">
                        <IonLabel class="ion-text-wrap"><strong>{t('end_computation')}</strong></IonLabel>
                    </IonItem>
                </IonCol>
                <IonCol className="ion-last-col-custom" size="2">
                    <IonItem className="item-header" lines="none">
                        <IonLabel class="ion-text-wrap"><strong>{t('results')}</strong>
                        </IonLabel>
                    </IonItem>
                </IonCol>
            </IonRow>

            {isLoading || isFetching ?
                <div>
                    <IonProgressBar type="indeterminate"/>
                </div> :
                listRunInputs ?
                    listRunInputs.results.map((value, idx) => {
                        return (
                            <Row key={idx} runInput={value} runOutput={value.runoutput}/>
                        )
                    }) : ""
            }
            {listRunInputs && totalPages ?
                <Pagination startCallback={() => setPage(1)}
                            backCallback={() => setPage(listRunInputs.previous ? page - 1 : page)}
                            nextCallback={() => setPage(listRunInputs.next ? page + 1 : page)}
                            endCallback={() => setPage(totalPages)} page={page}
                            total={totalPages}/> : ""
            }
        </IonGrid>);
};

export default RunEngineTable;