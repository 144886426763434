import React from "react";
import {IonContent, IonGrid, IonRow} from "@ionic/react";
import VppProductionUnit from "./VppProductionUnit";
import {useGetListVppProductionUnitQuery} from "../../../services/vpp-api";
import EmptyListVppProductionUnit from "../EmptyListVppProductionUnit";


const ListVppProductionUnit = () => {
    const { data, isLoading, isFetching } = useGetListVppProductionUnitQuery()

    return (
        <IonContent class="ion-padding">
            <IonGrid>
                <IonRow>
                    {data?.map((value, idx)=>{
                            return <VppProductionUnit key={value.id + value.start_date + value.end_date} isLoading={isLoading || isFetching} vppProductionUnit={value}/>
                        })}
                    {!isLoading && data?.length===0 ? <EmptyListVppProductionUnit/>:undefined}
                </IonRow>
            </IonGrid>
        </IonContent>
    );
};

export default ListVppProductionUnit;