import React from "react";
import {IonCard, IonCardContent, IonIcon, IonItem, IonLabel} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {locateOutline} from "ionicons/icons";


const EmptyListVppProductionUnit = () => {
    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'windpower' });

    return (
        <IonCard class="ion-card-production-unit">
            <IonItem color="primary">
                <IonIcon icon={locateOutline} slot="start" size='large'/>
                <IonLabel className="ion-text-wrap" ><b>{t('no_vpp_title')}</b></IonLabel>
            </IonItem>

            <IonCardContent>
                <IonItem color="warning">
                    <IonLabel>{t('no_vpp_message')}</IonLabel>
                </IonItem>
            </IonCardContent>
        </IonCard>
    );
};

export default EmptyListVppProductionUnit;