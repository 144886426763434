import React, {Fragment, ReactNode} from "react";

import {Redirect} from "react-router";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {isAuthenticated} from "../utils/utils";

interface ProtectedRouteProps {
    children?: ReactNode;
    defaultRedirect: string;
}

const ProtectedComponent: React.FC<ProtectedRouteProps> = ({ children, defaultRedirect, ...rest }) => {
    const auth = useSelector((state:RootState) => state.auth);
    // console.log("rendering ...")

    return (
        <Fragment>
            {isAuthenticated(auth) ? (
                children
            ) : (
                <Redirect to={defaultRedirect} />
            )}
        </Fragment>
    );

};
export default ProtectedComponent;




