import React from "react";
import './VppProductionUnit.css'
import {IonButton, IonContent, IonGrid, IonIcon, IonRow} from "@ionic/react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import GenerationUnit from "./GenerationUnit";
import {routes} from "../../../routes";
import {arrowBackCircle} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {useGetListGenerationUnitQuery} from "../../../services/vpp-api";

interface ListGenerationUnitProps
    extends RouteComponentProps<{
        id: string;
    }> {}

const ListGenerationUnit: React.FC<ListGenerationUnitProps> = ({ match }) => {

    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'windpower.generation_unit' });
    const {push} = useHistory();
    const { data: gus } = useGetListGenerationUnitQuery({power_unit_fk: parseInt(match.params.id)})
    return (
        <IonContent class="ion-padding">
            <IonGrid>
                <IonRow>
                    <IonButton color="medium" onClick={()=>push(routes.windpower)}>
                        <IonIcon slot="start" icon={arrowBackCircle}/>
                        {t('go_back')}
                    </IonButton>
                </IonRow>
                <IonRow>
                    {gus?.map((value, idx)=>{
                            return <GenerationUnit key={idx} {...value}/>
                        })}
                </IonRow>
            </IonGrid>
        </IonContent>
    );
};

export default ListGenerationUnit;