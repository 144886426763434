import {IonContent, IonGrid, IonImg, IonPage, IonProgressBar} from '@ionic/react';
import "./Loading.css"

const Loading: React.FC = () => {

    return (
        <IonPage>
            <IonContent className="loading-ion-content">
                <IonProgressBar className="progress-bar" type="indeterminate"/>
                <IonGrid className="loading-ion-grid">
                    <IonImg className="loading-ion-img" src={process.env.PUBLIC_URL + "/assets/images/logo_500px.png"}/>
                </IonGrid>
            </IonContent>
        </IonPage>

    );
};

export default Loading;
