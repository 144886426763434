import {
    IonAccordion,
    IonAccordionGroup,
    IonAvatar, IonChip,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRow, IonThumbnail,
} from '@ionic/react';

import {useLocation} from 'react-router-dom';
import './Menu.css';
import {useTranslation} from "react-i18next";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {
    faAtom,
    faBezierCurve, faBrain, faCircleDot,
    faCircleNodes,
    faDatabase,
    faFolderOpen, faGear,
    faHouse, faMoneyBill,
    faPlugCircleBolt, faSolarPanel, faTemperatureHalf, faWind
} from "@fortawesome/free-solid-svg-icons";


type AppPage = {
    url?: string
    icon?: JSX.Element
    title: string
    isNew?: boolean
    subPages?: AppPage[]
}

const appPages: AppPage[] = [
    {
        title: 'home',
        url: '/page/home',
        icon: <FontAwesomeIcon icon={faHouse} size={"2x"}/>,
    },
    {
        title: 'em',
        icon: <FontAwesomeIcon icon={faPlugCircleBolt}  size={"2x"}/>,
        subPages: [
            {
                title: 'dynamic_profiles',
                url: '/page/dynamic_profiles',
                icon: <FontAwesomeIcon icon={faBezierCurve} size={"2x"}/>,
            },
            {
                title: 'dynamic_profiles_history',
                url: '/page/dynamic_profiles_history',
                icon: <FontAwesomeIcon icon={faFolderOpen} size={"2x"}/>,
            },
            {
                title: 'cdc_engine',
                url: '/page/cdc_engine',
                icon: <FontAwesomeIcon icon={faCircleNodes} size={"2x"}/>,
                isNew: true,
            },
        ]
    },
    {
        title: 'data',
        icon: <FontAwesomeIcon icon={faDatabase} size={"2x"}/>,
        subPages: [
            {
                title: 'nuclear',
                url: '/page/nuclear',
                icon: <FontAwesomeIcon icon={faAtom} size={"2x"}/>,
            },
            {
                title: 'dayahead',
                url: '/page/dayahead',
                icon: <FontAwesomeIcon icon={faMoneyBill} size={"2x"}/>,
            },
            {
                title: 'temperatures',
                url: '/page/temperatures',
                icon: <FontAwesomeIcon icon={faTemperatureHalf} size={"2x"}/>,
            },
        ]
    },
    {
        title: 'vpp',
        icon: <FontAwesomeIcon icon={faCircleDot} size={"2x"}/>,
        subPages: [
            {
                title: 'windpower',
                url: '/page/windpower',
                icon: <FontAwesomeIcon icon={faWind} size={"2x"}/>,
            },
            {
                title: 'solarpower',
                url: '/page/solarpower',
                icon: <FontAwesomeIcon icon={faSolarPanel} size={"2x"}/>,
                isNew: true,
            },
        ]
    },
    {
        title: 'analysis',
        url: '/page/analysis',
        icon: <FontAwesomeIcon icon={faBrain} size={"2x"}/>,
    },
    {
        title: 'settings',
        url: '/page/settings',
        icon: <FontAwesomeIcon icon={faGear} size={"2x"}/>,
    },
];


const Menu: React.FC = () => {
    const location = useLocation();
    const { t } = useTranslation('translation', { keyPrefix: 'menu' });

    const MenuComponent = ({appPage, slot, padding_left}: {appPage: AppPage, slot?: string, padding_left?: string}) => {
        return <IonMenuToggle slot={slot} autoHide={false}>
            <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                <IonThumbnail slot="start" style={{"height": "100%", "paddingLeft": padding_left}}>
                    {appPage.icon}
                </IonThumbnail>
                <IonLabel>{t(appPage.title)}</IonLabel>
                {appPage.isNew ? <IonChip disabled={false} color={"success"}>NEW</IonChip> : undefined}
            </IonItem>
        </IonMenuToggle>
    }
    return (
        <IonMenu contentId="menuContent" type="overlay">
            <IonContent>
                <IonAvatar style={{margin: "auto", width:"100px", height:"100px"}}>
                    <img alt='logo' className="logo-img" src={process.env.PUBLIC_URL +"/assets/images/logo_500px.png"}/>
                </IonAvatar>
                <IonList id="inbox-list">
                    <IonListHeader>{t('menu')}</IonListHeader>
                    <IonNote>contact@holmium-consulting.com</IonNote>
                    {appPages.map((appPage, index) => {
                        if(appPage.url){
                            return <MenuComponent key={index} appPage={appPage}/>;
                        }else{
                            return (<IonAccordionGroup key={index}>
                                <IonAccordion>
                                    <IonItem slot="header" color="light">
                                        <IonThumbnail slot="start" style={{"height": "100%"}}>
                                            {appPage.icon}
                                        </IonThumbnail>
                                        <IonLabel>{t(appPage.title)}</IonLabel>
                                    </IonItem>
                                    {appPage.subPages?.map((subPage, index_subpage) => {
                                        return (<MenuComponent key={appPage.title + 'sub' + index_subpage} appPage={subPage} slot={"content"} padding_left={"20px"}/>)
                                    })}
                                </IonAccordion>
                            </IonAccordionGroup>)
                        }
                    })}
                </IonList>
            </IonContent>
            <IonRow >
                <IonCol class="ion-text-center">
                    <a href="https://aws.amazon.com/what-is-cloud-computing"><img width="120px" src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing"/></a>
                </IonCol>
            </IonRow>
        </IonMenu>
    );
};

export default Menu;
