import {IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import './DynamicProfilesHistory.css'
import {chevronDownOutline, chevronUpOutline} from "ionicons/icons";
import {useGetComputationResultQuery} from "../../services/profilage-api";
import LoadingHistoryTable from "../../components/dynamic_profiles_history/LoadingHistoryTable";
import Row from "../../components/dynamic_profiles_history/Row";
import Pagination from "../../components/dynamic_profiles_history/Pagination";

const PAGE_SIZE = 10

const DynamicProfilesHistory: React.FC = () => {

    const { t } = useTranslation('translation', { keyPrefix: 'history' });

    const [sortDirection, setSortDirection] = useState(false)
    const [page, setPage] = useState(1)


    const { data, isLoading, isFetching } = useGetComputationResultQuery({'ordering': sortDirection ? 'generation_date':'-generation_date', 'page': page, 'page_size': PAGE_SIZE})
    const totalPages = Math.max(Math.ceil(data ? data.count/PAGE_SIZE:1), 1)

    return (
        <IonContent>
            <IonGrid>
                <IonRow className="ion-justify-content-center no-border">
                    <IonCol className="no-border" size-sm="12" size-md="8" size-lg="7">
                        <IonRow class="row-header" >
                            <IonCol className="ion-col-custom" size="4">
                                <IonItem button={true} lines="none" onClick={(e)=>setSortDirection(!sortDirection)}>
                                    <IonLabel><strong>{t('horodate')}</strong></IonLabel>
                                    <IonIcon slot="end" icon={sortDirection ? chevronDownOutline : chevronUpOutline}/>
                                </IonItem>
                            </IonCol>
                            <IonCol className="ion-col-custom" size="2">
                                <IonItem lines="none">
                                    <IonLabel><strong>{t('type')}</strong></IonLabel>
                                </IonItem>
                            </IonCol>
                            <IonCol className="ion-col-custom" size="2">
                                <IonItem lines="none">
                                    <IonLabel><strong>{t('last_coeff_verified')}</strong></IonLabel>
                                </IonItem>
                            </IonCol>
                            <IonCol className="ion-col-custom" size="2">
                                <IonItem lines="none">
                                    <IonLabel><strong>{t('last_coeff_anticipated')}</strong></IonLabel>
                                </IonItem>
                            </IonCol>
                            <IonCol className="ion-col-custom" size="2" >
                                <IonItem lines="none">
                                    <IonLabel><strong>{t('action')}</strong></IonLabel>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        {isLoading || isFetching ? <LoadingHistoryTable/> : data?data.results.map((value, idx)=> {
                            return (
                                <Row key={idx} computationResult={value}/>
                            )
                        }):""}
                        {data&&totalPages?<Pagination startCallback={()=>setPage(1)}
                                  backCallback={()=>setPage(data.previous?page-1:page)}
                                  nextCallback={()=>setPage(data.next?page+1:page)}
                                  endCallback={()=>setPage(totalPages)} page={page} total={totalPages} /> : ""}

                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    );
};

export default DynamicProfilesHistory;