import {IonCol, IonContent, IonGrid, IonProgressBar, IonRow} from "@ionic/react";
import {useGetUserQuery} from "../services/user-api";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import Profile from "../components/settings/Profile";
import DeleteAccount from "../components/settings/Delete";


const Settings: React.FC = () => {

    const auth = useSelector((state: RootState) => state.auth)
    const { data, isLoading } = useGetUserQuery(auth.user_email)


    return (
        <IonContent fullscreen>
            <IonProgressBar hidden={!isLoading} type="indeterminate"/>
            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol size-sm="12" size-md="8" size-lg="8">
                        {data ? <Profile user={data}/>: null}
                        <DeleteAccount/>
                    </IonCol>
                </IonRow>
            </IonGrid>

        </IonContent>

    );
};

export default Settings;