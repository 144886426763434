import React from "react";
import {IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {GenerationUnitType} from "../../../types/types";
import './VppProductionUnit.css'
import {settingsOutline} from "ionicons/icons";

const GenerationUnitParameters = (props: GenerationUnitType) => {
    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'windpower.generation_unit' });

    return (
        <IonGrid>
            <IonRow>
                <IonItem lines={"full"}>
                    <IonLabel>
                        <b>{t('parameters')}</b>
                    </IonLabel>
                    <IonIcon icon={settingsOutline} slot={"start"}/>
                </IonItem>
            </IonRow>
            <IonRow>
                <IonCol size="6">
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h3>{t('nominal_power')}</h3>
                            <p>{props.nominal_power}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h3>{t('latitude')}</h3>
                            <p>{props.latitude}</p>
                        </IonLabel>
                    </IonItem>
                </IonCol>
                <IonCol size="6">
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h3>{t('longitude')}</h3>
                            <p>{props.longitude}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h3>{t('height')}</h3>
                            <p>{props.height}</p>
                        </IonLabel>
                    </IonItem>
                </IonCol>
            </IonRow>
        </IonGrid>

    );
};

export default GenerationUnitParameters;