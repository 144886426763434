
export type AuthState = {
  access: string
  refresh: string
  is_active: boolean
  user_email: string
  user_id: number | undefined
  expire: number
}

export type LoginRequest = {
  email: string
  password: string
}


export type VppProductionUnitType = {
  id: number
  name: string
  contract_product_fk: number
  nbr_active_generation_units: number
  nominal_power: number
  start_date: string
  end_date: string
}

export type GenUnitUnavailabilityType = {
  id: number
  generation_unit_fk : number
  start_datetime : string
  end_datetime : string
  power_available : number
}

export type ForecastPowerOutput = {
  horodate : string
  power : number
}

export type GenerationUnitType = {
  id: number
  power_unit_fk: number
  reference: string
  model: number
  nominal_power: number
  latitude: number
  longitude: number
  height: number
}


export type ComputationResultPagination = {
  id: number
  count: number
  next: string
  previous: string
  results: ComputationResult[]
}

export type ComputationResult = {
  id: number
  generation_date: string
  product_code: ProductCode
  resultat: string
  horodate_max_coef_verifie: string
  horodate_max_coef_anticipe: string
}

export type ProfilPredicted = {
  horodate: string
  generation_date: string
  profil: Profil
  sous_profil: SousProfil
  product: ProductCode
  valeur: number
}

export type ProfilRealized = {
  horodate: string
  generation_date: string
  profil: Profil
  sous_profil: SousProfil
  nature: Nature
  valeur: number
}

export type TemperatureMeteoFrance = {
  horodate: string
  valeur: number
}

export type Contract = {
  id: number
  name: string
}

export type ContractProduct = {
  id: number
  start_date: string
  end_date: string
  product_code: ProductCode
}



export enum Nature {
  ANTICIPE="DYNANTI",
  VERIFIE="DYNVERI"
}

export enum ProductCode {
  J1="J+1",
  J3="J+3",
  J4="J+4",
  J7='J+7',
  J9='J+9',
  LONG="LONG",
  EXTREM="EXTREM",
  NONE='NONE',
  PRD3='PRD3'
}

export enum Profil {
  PRO1="PRO1",
  PRO2="PRO2",
  PRO5="PRO5",
  RES1="RES1",
  RES2="RES2",
  RES11="RES11",
  PRD3="PRD3"
}

export enum SousProfil {
  BASE="BASE",
  HP="HP",
  HC="HC"
}

export type Adresse = {
  escalier_etage_appartement: string
  batiment: string
  numero_libelle_voie: string
  lieu_dit: string
  code_postal: string
  code_insee: string
  libelle_commune: string
}

export type ToastMessage = {
  message: string;
  color: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark"
}

export type RunInputPagination = {
    id: number,
    count: number,
    next: string,
    previous: string,
    results: RunInput[],
}

export type RunInputStatusEnum = "INIT" | "STEP_PARSING" | "STEP_API" | "STEP_CLUSTER" | "STEP_MAP" | "STEP_AGGREGATION" | "STEP_SAVE" | "SUCCESS" | "FAILED"
export type DemandTypeRunInput = "PROFILE" | "TLR"

export type RunInput = {
    user_id: number,
    demand_id: number,
    creation_datetime: string,
    input_type: string,
    file: string,
    demand_type: DemandTypeRunInput,
    status: RunInputStatusEnum,
    runoutput?: RunOutput,
}

export type RunOutput = {
  creation_datetime: string,
  file_loadcurve: string,
  file_metadata: string,
  file_logs: string,
}

export type RegisterRequest = {
  first_name: string
  last_name: string
  email: string
  password: string
  passwordConfirmation: string
  phone_number: string
}

export type User = {
  id: number
  contract_fk: number
  first_name: string
  last_name: string
  email: string
  password?: string
  passwordConfirmation?: string
  phone_number: string
  is_staff: boolean
}


export type DayaheadPrice = {
  id: number
  horodate: string
  valeur: number
  country: string
}


export type ImbalancePrice = {
  id: number
  horodate: string
  pre_pos: number
  pre_neg: number
  country: string
}

export type PowerData = {
  id: number
  horodate: string
  creation_date: string
  country: string
  production_type: string
  valeur: number
}

export type PowerAvailability = PowerData

export type PowerGeneration = PowerData

export type TemperatureOpenDataEnedis = {
  id: number
  horodate: string
  valeur: number
  type: string
}


export {};