import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {authApi, authApiReducer} from "./services/auth/auth-api";
import {setupListeners} from "@reduxjs/toolkit/query";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {authReducer} from "./slices/auth/auth.slice";
import {messageReducer} from "./slices/message.slice";
import {userApi, userApiReducer} from "./services/user-api";
import {profilageApi, profilageApiReducer} from "./services/profilage-api";
import {dataApi, dataApiReducer} from "./services/data-api";
import {vppApi, vppApiReducer} from "./services/vpp-api";
import {profilesApi, profilesApiReducer} from "./services/profiles-api";

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth']
}

const rootReducer = combineReducers({
    auth: authReducer,
    message: messageReducer,
    // Add the generated API reducer as a specific top-level slice
    [authApi.reducerPath]: authApiReducer,
    [userApi.reducerPath]: userApiReducer,
    [profilageApi.reducerPath]: profilageApiReducer,
    [profilesApi.reducerPath]: profilesApiReducer,
    [dataApi.reducerPath]: dataApiReducer,
    [vppApi.reducerPath]: vppApiReducer,
})

export const store = configureStore({
    reducer: persistReducer(rootPersistConfig, rootReducer),
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful slices of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat([authApi.middleware, userApi.middleware, profilesApi.middleware,
            profilageApi.middleware, dataApi.middleware, vppApi.middleware]),
})

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch