import React, {useRef} from "react";
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet'
import {LatLngTuple} from 'leaflet';

interface MapProps {
    positions : LatLngTuple[]
    zoom?: number,
    messages : string[]
}

const CustomMap = ({positions, zoom, messages}: MapProps) => {
    const barycentre = positions.reduce(function (barycentre, pos) {
        return [barycentre[0] + pos[0]/positions.length, barycentre[1] + pos[1]/positions.length] }, [0, 0])
    const reference_resize:React.MutableRefObject<boolean> = useRef<boolean>(true)

    const maxLatLng = positions.length > 0 ? positions.reduce(function (acc, pos) { return [Math.max(acc[0], pos[0]), Math.max(acc[1], pos[1])]}) : [0, 0] as LatLngTuple
    const minLatLng = positions.length > 0 ? positions.reduce(function (acc, pos) { return [Math.min(acc[0], pos[0]), Math.min(acc[1], pos[1])]}) : [0, 0] as LatLngTuple
    const outerBounds = [minLatLng, maxLatLng]
    const nbr_elements = positions.length
    const ComponentResize = ({reference_resize, zoom}:{reference_resize: React.MutableRefObject<boolean>, zoom?: number}) => {
        const map = useMap()

        if (reference_resize.current) {
            setTimeout(() => {
                map.invalidateSize()
                if(nbr_elements >= 1){
                    map.fitBounds(outerBounds)
                    map.setZoom(zoom!)
                    if(nbr_elements === 1 && zoom === undefined){
                        map.zoomOut(4)
                    }else{
                        map.zoomOut(1)
                    }
                }else{
                    map.setView([0, 0], 0);
                }
                reference_resize.current = false
            }, 0)
        }
        return null
    }

    return (
        <MapContainer
            center={barycentre}
            scrollWheelZoom={true}
            style={{ width: "100%", height: "300px" }}
        >
            <ComponentResize reference_resize={reference_resize} zoom={zoom}/>
            <TileLayer
                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {positions.map((pos, idx)=>{
                return <Marker key={idx} position={pos}>
                    {messages.length > 0 ? <Popup>
                        {messages![idx]}
                    </Popup> : undefined}
                </Marker>
            })
            }
        </MapContainer>
    );
};

export default CustomMap;