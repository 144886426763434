import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {IonToast} from "@ionic/react";
import {clearToastMessage} from "../slices/message.slice";

const CustomToast: React.FC<{message?:string | undefined}> = ({message}) => {
    const dispatch = useDispatch()
    const toastMessage = useSelector((state: RootState) => {
        return state.message;
    })

    return (
        <IonToast
            color={toastMessage.color}
            isOpen={!!(toastMessage.message) || !!(message)}
            onDidDismiss={()=>{dispatch(clearToastMessage())}}
            message={toastMessage.message || message}
            duration={2000}
        />
    );
};

export default CustomToast;
