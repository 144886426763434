import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonSkeletonText
} from "@ionic/react";
import {analyticsOutline, folderOpenOutline, informationCircleOutline, stopwatchOutline} from "ionicons/icons";

import {useTranslation} from 'react-i18next';
import {useGetComputationResultQuery} from "../../services/profilage-api";
import React from "react";
import {useHistory} from "react-router-dom";
import {routes} from "../../routes";
import {formatDate} from "../../utils/utils";


const Infos: React.FC = () => {
    const {push} = useHistory();
    const { t, i18n } = useTranslation(['translation', 'common'], { keyPrefix: 'home' });
    const { data, isLoading, isFetching } = useGetComputationResultQuery({'ordering': '-generation_date', 'page': 1, 'page_size': 1})



    return (
        <IonGrid>
            <IonRow>
                <IonCol sizeLg="12" sizeMd="12" sizeSm="12">
                    <IonCard>
                        <IonItem color="success">
                            <IonIcon icon={informationCircleOutline} slot="start" size='large'/>
                            <IonLabel className="ion-text-wrap"><b>{t("news.header")}</b></IonLabel>
                        </IonItem>
                        <IonCardContent>
                            {t("news.body")}
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4" sizeMd="6" sizeSm="12">
                    <IonCard>
                        <IonItem color="dark">
                            <IonIcon icon={analyticsOutline} slot="start" size='large'/>
                            <IonLabel className="ion-text-wrap" ><b>8 {t("computed_profiles.header")}</b></IonLabel>
                            <IonButton color="light" fill="outline" slot="end" onClick={()=>push(routes.dynamic_profiles_history)}>{t("common:view")}</IonButton>
                        </IonItem>
                        <IonCardContent>
                            {t("computed_profiles.body")}
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                <IonCol sizeLg="4" sizeMd="6" sizeSm="12">
                    <IonCard>
                        <IonItem color="medium">
                            <IonIcon icon={stopwatchOutline} slot="start" size='large'/>
                            <IonLabel className="ion-text-wrap">
                                {isLoading || isFetching ? <IonSkeletonText animated style={{ width: '100%' }} /> : <b>{t("last_run.header")} : {data && data.results.length>0 ? formatDate(data.results[0].generation_date, i18n) : <i>{t("last_run.no_computation")}</i>}
                                </b>}
                            </IonLabel>
                            <IonButton color="light" fill="outline" slot="end" onClick={()=>push(routes.dynamic_profiles_history)}>{t("common:view")}</IonButton>
                        </IonItem>
                        <IonCardContent>
                            {t("last_run.body")}
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                <IonCol sizeLg="4" sizeMd="12" sizeSm="12">
                    <IonCard>
                        <IonItem color="light">
                            <IonIcon icon={folderOpenOutline} slot="start" size='large'/>
                            <IonLabel className="ion-text-wrap">
                                {isLoading || isFetching ? <IonSkeletonText animated style={{ width: '100%' }} /> : <b>{t("nbr_run.header")} : {data ? data.count: ""}
                                </b>}
                            </IonLabel>
                            <IonButton color="dark" fill="outline" slot="end" onClick={()=>push(routes.dynamic_profiles_history)}>{t("common:view")}</IonButton>
                        </IonItem>
                        <IonCardContent>
                            {t("nbr_run.body")}
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>

    );
};

export default Infos;