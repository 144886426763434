import React from "react";
import {IonCol, IonDatetime, IonIcon, IonInput, IonItem, IonLabel, IonPopover, IonRow} from "@ionic/react";
import {calendarOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../utils/utils";
import {DatetimePresentation} from "@ionic/core/dist/types/components/datetime/datetime-interface";


export type DateSelectorInterface = {
    endDate: Date | undefined,
    startDate: Date,
    setStartDate: (a:Date)=> void,
    setEndDate: (a:Date)=> void,
    unique_key: string | number,
    presentation?: DatetimePresentation
    disabled?: boolean
};

const DateSelector = ({startDate, endDate, setStartDate, setEndDate, unique_key, presentation, disabled=false} : DateSelectorInterface) => {

    const { t, i18n } = useTranslation(['common'], { keyPrefix: 'datetime' });

    return (
        <IonRow>
            <IonCol size-sm="12" size-md="6" size-lg="6">
                <IonItem button id={"open-start-date-input-"+unique_key}>
                    <IonIcon slot="start" icon={calendarOutline} />
                    <IonLabel position="floating">{t("start_date")}</IonLabel>
                    <IonInput disabled={disabled} type='text' value={formatDate(startDate, i18n)}/>
                    <IonPopover trigger={"open-start-date-input-"+unique_key} showBackdrop={false}>
                        <IonDatetime
                            presentation={presentation ? presentation : "date"}
                            hourCycle={"h23"}
                            onIonChange={ev => setStartDate(new Date(ev.detail.value as string))}
                            showDefaultButtons={true}
                        />
                    </IonPopover>
                </IonItem>
            </IonCol>
            <IonCol size-sm="12" size-md="6" size-lg="6">
                <IonItem button id={"open-end-date-input-"+unique_key}>
                    <IonIcon slot="start" icon={calendarOutline} />
                    <IonLabel position="floating">{t("end_date")}</IonLabel>
                    <IonInput disabled={disabled} type='text' value={formatDate(endDate, i18n)}/>
                    <IonPopover trigger={"open-end-date-input-"+unique_key} showBackdrop={false}>
                        <IonDatetime
                            presentation={presentation ? presentation : "date"}
                            hourCycle={"h23"}
                            onIonChange={ev => setEndDate(new Date(ev.detail.value as string))}
                            showDefaultButtons={true}
                        />
                    </IonPopover>
                </IonItem>
            </IonCol>
        </IonRow>

    );
};

export default DateSelector;