import {IonContent} from "@ionic/react";
import React from "react";
import {RouteComponentProps} from "react-router-dom";
import EmptyListVppProductionUnit from "../../components/vpp/EmptyListVppProductionUnit";


const SolarPower: React.FC<RouteComponentProps> = ({ match }) => {

    return (
        <IonContent class="ion-padding">
            <EmptyListVppProductionUnit/>
        </IonContent>
    );
};


export default SolarPower;
