import {AuthState} from "../types/types";
import md5 from "md5";
import {i18n} from "i18next";
import dateFormat from "dateformat";
import moment from "moment/moment";


export function formatDate(value: string | Date | undefined, _i18n: i18n, isDatetime=false): string {
    if(value === undefined){
        return ''
    }
    let format:Record<string, string> = {year: 'numeric', month: 'numeric', day: 'numeric'}
    format = isDatetime ? {...format, hour:'numeric', minute:'numeric', second: 'numeric'} : format
    return _i18n.t('common:intlDateTime', {
            val: typeof value ==="string"?Date.parse(value):value,
            formatParams: {
                val: format,
            }
        })
}

export function formatDatetimeQuery(value: Date, startOfDay=true, format:"isoDate"|"isoDatetime"="isoDatetime"): string {
    let formatted = moment(value).local()
    formatted = startOfDay ? formatted.startOf('day') : formatted
    if(format === "isoDate"){
        return dateFormat(formatted.toDate(), format)
    }
    return formatted.toISOString()
}

export function getBaseUrl(): string {
    return (process.env.REACT_APP_API_URL || "").replace(/\/+$/, '')
        .concat('/', process.env.REACT_APP_BACKEND_URL_API || "", "/")
}

export function getInitials(name: string) {
    var names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}

export function isAuthenticated(auth: AuthState): boolean {
    return (auth.expire >= new Date().getTime()) && auth.is_active && (auth.access !== undefined) && (auth.access !== null) && (auth.access !== "")
}

export function genUrlGravatar(email: string | undefined, size?: number): string{
    return "https://gravatar.com/avatar/" + (email ? md5(email): "00000000000000000000000000000000") + "?d=monsterid" + (size ? "&s=" + size: "");
}


export function addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result
}

export function shadeColor(color: string, percent: number) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = Math.round((255-R) * percent / 100) + R;
    G = Math.round((255-G) * percent / 100) + G;
    B = Math.round((255-B) * percent / 100) + B;

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

export function items(obj: any[]){

    var ret:any[] = [];
    obj.forEach((value, index)=>{
        ret.push(Object.freeze([value, obj[index]]));
    })
    return Object.freeze(ret);
}