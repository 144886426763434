import {useTranslation} from "react-i18next";
import dateFormat from "dateformat";
import React, {useEffect, useState} from "react";
import {useGetProfilPredictedQuery, useGetProfilRealizedQuery} from "../../services/profilage-api";

import {Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import {Nature, ProductCode, Profil, ProfilPredicted, ProfilRealized, SousProfil} from "../../types/types";
import _ from 'underscore';
import {formatDate, shadeColor} from "../../utils/utils";
import moment from 'moment'

type GraphicProps = {
    start_date: Date;
    end_date: Date,
    generation_date: Date,
    product: ProductCode,
    profil: Profil,
    sous_profils: SousProfil[],
    color: string
};

const Graphic = ({start_date, end_date, generation_date, product, profil, sous_profils, color} : GraphicProps) => {

    const [dataComputed, setDataComputed] = useState<any[]>([])
    const { t, i18n } = useTranslation(['translation', 'common'], { keyPrefix: 'visualization' });

    let start_query = dateFormat(moment(start_date).local().startOf('day').toDate(), "isoDateTime")
    let end_query = dateFormat(moment(end_date).local().startOf('day').toDate(), "isoDateTime")

    const { data:dataPredicted, isLoading:isLoadingPredicted, isFetching: isFetchingPredicted } = useGetProfilPredictedQuery(
        {'horodate__gte': start_query,
            'horodate__lte': end_query,
            'generation_date': dateFormat(generation_date, "isoDate"),
            'profil__in': profil,
            'sous_profil__in': sous_profils,
            'product_code': product}
    )

    const { data:dataRealized, isLoading:isLoadingRealized, isFetching:isFetchingRealized } = useGetProfilRealizedQuery(
        {'horodate__gte': start_query,
            'horodate__lte': end_query,
            'profil__in': profil,
            'sous_profil__in': sous_profils}
    )

    const actives = dataComputed.reduce((acc, datum) => ({...acc, ...datum}), {});

    useEffect(() => {
        let _process: any = {};
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        let condition = !isLoadingRealized && !isLoadingPredicted && !isFetchingPredicted && !isFetchingRealized && dataPredicted && dataRealized
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        condition ? dataPredicted.map((pp: ProfilPredicted, index)=>{
            if(pp.horodate in _process){
                _process[pp.horodate][pp.sous_profil] = pp.valeur
            }else{
                _process[pp.horodate] = {}
                _process[pp.horodate][pp.sous_profil] = pp.valeur
            }
            _process[pp.horodate]['horodate'] = formatDate(pp.horodate, i18n, true)
            _process[pp.horodate]['sort'] = new Date(pp.horodate)
            return undefined
        }) : undefined
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        condition ? dataRealized.map((pr: ProfilRealized, index)=>{
            if(pr.horodate in _process){
                _process[pr.horodate][pr.sous_profil+'_'+pr.nature] = pr.valeur
            }else{
                _process[pr.horodate] = {}
                _process[pr.horodate][pr.sous_profil+'_'+pr.nature] = pr.valeur
            }
            _process[pr.horodate]['horodate'] = formatDate(pr.horodate, i18n, true)
            _process[pr.horodate]['sort'] = new Date(pr.horodate)
            return undefined
        }) : undefined
        _process = _.values(_process)
        const sortedProcess = _process.sort((a:any, b:any) => a.sort - b.sort )
        setDataComputed(sortedProcess)
    }, [dataPredicted, dataRealized, i18n, isFetchingPredicted, isFetchingRealized, isLoadingPredicted, isLoadingRealized, t]); // N’exécute l’effet que
    // si count a changé

    return (
        <ResponsiveContainer width="100%" height={500}>
            <ComposedChart
                width={500}
                height={200}
                data={dataComputed}
                syncId="anyId"
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="horodate" interval="preserveStartEnd"/>
                <Tooltip/>
                <YAxis />
                <Legend />

                {Object.keys(actives).map((data_key, idx)=> {
                    return (
                        // @ts-ignore
                        Object.values(Nature).map((nature: string, index)=>{
                            if(data_key.includes(nature)){
                                let strokeDasharray = undefined
                                let strokeWidth = 1
                                let customColor = color
                                let name = data_key.split('_')[0]
                                if(nature === Nature.ANTICIPE){
                                    strokeDasharray="5 5"
                                    strokeWidth = 2
                                    name = name + '_ANTICIPE'
                                }
                                if(nature === Nature.VERIFIE){
                                    customColor = "#D62246"
                                    strokeDasharray="10 5 10 5"
                                    strokeWidth = 2
                                    name = name + '_VERIFIE'
                                }
                                // @ts-ignore
                                return <Line key={data_key + nature + idx} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray} stroke={customColor} type="monotone" dataKey={data_key} dot={false} name={name}/>
                            }
                        })
                    )
                })
                }
                {_.intersection(Object.keys(actives), Object.keys(SousProfil)).map((data_key, idx)=> {
                    const fill = shadeColor(color, Math.min(80, idx*50))
                    return <Area key={data_key + idx} fill={fill} stroke={fill} type="monotone" dataKey={data_key} dot={false} legendType="circle"/>
                })
                }

            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default Graphic;