import {IonContent, IonRouterOutlet} from "@ionic/react";
import React from "react";
import {Redirect, Route} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import ListVppProductionUnit from "../../components/vpp/windpower/ListVppProductionUnit";
import ListGenerationUnit from "../../components/vpp/windpower/ListGenerationUnit";
import GenerationUnit from "../../components/vpp/windpower/GenerationUnit";


const Dayahead: React.FC<RouteComponentProps> = ({ match }) => {

    return (
        <IonRouterOutlet>
            <Route exact path={match.url} component={ListVppProductionUnit} />
            <Route path={`${match.url}/productionunit/:id`} component={ListGenerationUnit} />
            <Route path={`${match.url}/productionunit/:id/:generationunit_id`} component={GenerationUnit} />
            <Route render={() => <Redirect to={match.url} />} />
        </IonRouterOutlet>
    );
};


export default Dayahead;
