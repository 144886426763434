import {IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow} from "@ionic/react";
import {analyticsOutline} from "ionicons/icons";
import React, {useState} from "react";
import {addDays} from "../../utils/utils";
import SimpleGraphic, {SimpleGraphicInterface} from "./SimpleGraphic";
import DateSelector from "./DateSelector";
import MultipleDateSelector from "./MultipleDateSelector";

interface SimpleGraphicDateSelectorInterface extends SimpleGraphicInterface {
    unique_key: string | number,
    label: string,
    active_multi_date_selector: boolean[],
}

const SimpleGraphicDateSelector = ({unique_key, label, fc_queries, tooltip_add, custom_filter, data_names,
                                       line_type, names, colors, connect_nulls, active_multi_date_selector} : SimpleGraphicDateSelectorInterface) => {
    const [startDate, setStartDate] = useState<Date>(addDays(new Date(), -15));
    const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 30));
    const [creationDates, setCreationDates] = useState<Date[]>([addDays(new Date(), 0)]);

    return (
        <IonCard>
            <IonItem color="primary">
                <IonIcon icon={analyticsOutline} slot="start" size='large'/>
                <IonLabel className="ion-text-wrap" ><b>{label}</b></IonLabel>
            </IonItem>
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <DateSelector unique_key={unique_key} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate}/>
                        </IonCol>
                    </IonRow>
                    {active_multi_date_selector.some(el=>el) ?
                    <IonRow>
                        <IonCol>
                            <MultipleDateSelector unique_key={unique_key}
                                                  creationDates={creationDates}
                                                  delCreationDate={(idx) => setCreationDates([...creationDates.slice(0, idx), ...creationDates.slice(idx+1, creationDates.length)])}
                                                  addCreationDate={(date) => setCreationDates([...creationDates, date])}/>
                        </IonCol>
                    </IonRow> : undefined}
                    <IonRow>
                        <IonCol>
                            <SimpleGraphic start_date={startDate}
                                           end_date={endDate}
                                           creation_dates={active_multi_date_selector.map((multi_dates, idx)=>{return multi_dates ? creationDates: []})}
                                           fc_queries={fc_queries}
                                           tooltip_add={tooltip_add}
                                           custom_filter={custom_filter}
                                           data_names={data_names}
                                           line_type={line_type}
                                           names={names}
                                           connect_nulls={connect_nulls}
                                           colors={colors}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    );
};

export default SimpleGraphicDateSelector;