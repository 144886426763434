import {IonContent, IonPage, IonRouterOutlet, IonSplitPane} from '@ionic/react';
import './Page.css';
import Home from "./Home";
import Settings from "./Settings";
import Menu from "../components/Menu";
import React from "react";
import {Redirect, Route} from "react-router";
import Header from "../components/header/Header";
import {routes} from "../routes";
import Temperatures from "./data/Temperatures";
import Dayahead from "./data/Dayahead";
import Nuclear from "./data/Nuclear";
import DynamicProfilesHistory from "./data/DynamicProfilesHistory";
import DynamicProfiles from "./data/DynamicProfiles";
import WindPower from "./vpp/WindPower";
import SolarPower from "./vpp/SolarPower";
import CdcEngine from "./CdcEngine";
import Analysis from "./Analysis";


const Page: React.FC = () => {

    return (
        <IonContent fullscreen>
            <IonSplitPane when="lg" contentId="menuContent">
                <Menu />
                <IonPage id="menuContent">
                    <Header />
                    <IonContent>
                        <IonRouterOutlet>
                            <Route path={routes.home} exact component={Home}/>
                            <Route path={routes.dynamic_profiles} exact component={DynamicProfiles}/>
                            <Route path={routes.dynamic_profiles_history} exact component={DynamicProfilesHistory}/>
                            <Route path={routes.nuclear} exact component={Nuclear}/>
                            <Route path={routes.dayahead} exact component={Dayahead}/>
                            <Route path={routes.temperatures} exact component={Temperatures}/>
                            <Route path={routes.windpower} component={WindPower}/>
                            <Route path={routes.solarpower} component={SolarPower}/>
                            <Route path={routes.cdc_engine} component={CdcEngine}/>
                            <Route path={routes.analysis} component={Analysis}/>
                            <Route path={routes.settings} exact component={Settings}/>
                            <Route render={() => <Redirect to={routes.non_existing} />} />
                        </IonRouterOutlet>
                    </IonContent>
                </IonPage>
            </IonSplitPane>
        </IonContent>

    );
};

export default Page;