import {IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar} from "@ionic/react";
import {useLocation} from "react-router-dom";
import Avatar from "./Avatar";
import LanguageSelector from "./LanguageSelector";
import {useTranslation} from "react-i18next";

const Header: React.FC = () => {

    const location = useLocation();

    const { t } = useTranslation('translation', { keyPrefix: 'menu' });

    let name = location.pathname.split('/').slice(-1)[0];
    if(!Number.isNaN(Number(name))){
        name = t(location.pathname.split('/').slice(0, -1).slice(-1)[0]) + ' n°' + name;
    }else{
        name = t(name)
    }

    return (
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton autoHide={true}/>
                </IonButtons>
                <IonTitle style={{"textAlign": "left"}}>{name}</IonTitle>
                <LanguageSelector />
                <Avatar />
            </IonToolbar>
        </IonHeader>
    );
};

export default Header;