import {IonApp, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Route, Switch} from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import {Provider} from "react-redux";
import {persistor, store} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import Loading from "./pages/Loading";
import React, {Suspense} from "react";
import {Redirect} from "react-router";
import Login from "./pages/auth/Login";
import Page from "./pages/Page";
import Page404 from "./pages/Page404";
import CustomToast from "./components/Toast";
import {routes} from "./routes";
import ProtectedComponent from "./components/ProtectedRoute";

setupIonicReact();

const App: React.FC = () => {
  return (
      <Provider store={store}>
        <PersistGate loading={<Loading/>} persistor={persistor}>
          <Suspense fallback={<Loading/>}>
            <IonApp>
            <CustomToast />
            <IonReactRouter basename={process.env.PUBLIC_URL || ''}>
              {/*Le composant IonRouterOutled ne fonctionne pas, il faut utiliser le Switch de React*/}
              <Switch>

                <Route path="/" exact>
                  <Redirect to={routes.home} />
                </Route>

                <Route path="/auth" exact component={Login}/>

                <Route path={routes.non_existing} exact component={Page404}/>

                <Route path={routes.login} exact component={Login}/>

                <ProtectedComponent defaultRedirect={routes.login}>
                  <Route path="/page/:name" exact={false} component={Page}/>
                </ProtectedComponent>

                <Route render={() => <Redirect to={routes.non_existing} />} />

              </Switch>
            </IonReactRouter>
          </IonApp>
          </Suspense>

        </PersistGate>
      </Provider>
  );
};

export default App;
