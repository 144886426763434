import {
    IonAlert,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {eyeOffOutline, eyeOutline, personOutline} from "ionicons/icons";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useLoginMutation} from "../../services/auth/auth-api";
import {LoginRequest} from "../../types/types";
import {setToastMessage} from "../../slices/message.slice";
import {useDispatch, useSelector} from "react-redux";
import "./Login.css"
import {RootState} from "../../store";
import {isAuthenticated} from "../../utils/utils";
import {Redirect} from "react-router";
import {routes} from "../../routes";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../../components/header/LanguageSelector";

function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}

const Login: React.FC = () => {
    const {push} = useHistory();
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [formState, setFormState] = useState<LoginRequest>({email:'', password:''})
    const [login, // This is the mutation trigger
        { isLoading }, // This is the destructured mutation result
    ] = useLoginMutation()
    const auth = useSelector((state:RootState) => state.auth)
    const [msg, setMsg] = useState<string>("");
    const [iserror, setIserror] = useState<boolean>(false);
    const { t } = useTranslation('translation', { keyPrefix: 'login' });

    const handleClick = () => {
        setShow(!show);
    }

    const handleChange = ({detail:{value}, target}: CustomEvent) =>{
        // @ts-ignore
        setFormState((prev) => ({ ...prev, [target.name]: value }));
    }

    const handleLogin = () => {

        if (!formState.email || !validateEmail(formState.email)) {
            setMsg("Your email is invalid");
            setIserror(true);
            return;
        }

        if (!formState.password) {
            setMsg("Please enter your password");
            setIserror(true);
            return;
        }
        login(formState).then((result) => {
                return (!("error" in result)) ? push(routes.home) : dispatch(setToastMessage({message:'Email or' +
                        ' password incorrect', color:"warning"}))
            }
        )
    };

    if(isAuthenticated(auth)){
        return <Redirect to="/page/home" />
    }else{
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{t('login')}</IonTitle>
                        <LanguageSelector />
                    </IonToolbar>
                </IonHeader>

                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    header={"Error"}
                    message={msg}
                    buttons={["Dismiss"]}
                />
                <IonLoading
                    isOpen={isLoading}
                    message={'Please wait...'}
                    duration={5000}
                />

                <IonContent>
                    <form>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size-sm="12" size-md="6" size-lg="5">
                                    <IonRow className="ion-row-icon">
                                        <IonCol style={{display: "flex", "justifyContent": "center"}}>
                                            <IonIcon
                                                color="primary"
                                                style={{ fontSize: "60px"}}
                                                icon={personOutline}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem fill={"outline"} className="custom-ion-item">
                                                <IonLabel position="floating">{t('email')}</IonLabel>
                                                <IonInput type="email"
                                                          autocomplete="email"
                                                          autocorrect="on"
                                                          name="email"
                                                          value={formState.email}
                                                          pattern="/\S+@\S+\.\S+/"
                                                          onIonChange={handleChange} />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem fill={"outline"} className="custom-ion-item">
                                                <IonLabel position="floating">{t('password')}</IonLabel>
                                                <IonInput
                                                    type={show ? "text" : "password"}
                                                    autocomplete={"current-password"}
                                                    name="password"
                                                    value={formState.password}
                                                    onIonChange={handleChange}
                                                >
                                                </IonInput>
                                                <IonButton fill="clear" slot="end" onClick={handleClick}>
                                                    <IonIcon
                                                        color="dark"
                                                        style={{ fontSize: "25px"}}
                                                        icon={show ? eyeOutline : eyeOffOutline }
                                                    />
                                                </IonButton>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton expand="block" onClick={handleLogin}>{t('login')}</IonButton>
                                            <p style={{ fontSize: "medium" }}>
                                                {t('dont_have_account')} <a href="mailto: contact@holmium-consulting.com">{t('register_now')}</a>
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </form>
                </IonContent>
            </IonPage>
        );
    }
};

export default Login;
