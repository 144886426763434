import {IonIcon, IonItem, IonLabel, IonList, useIonPopover} from "@ionic/react";
import "./Avatar.css"
import {languageOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import Flag from 'react-world-flags'
import {i18n_languages} from "../../i18n";


const PopoverList: React.FC<{changeLanguage: (code: string) => void}> = ({ changeLanguage }) => {
    const { t } = useTranslation('translation', {keyPrefix: "header"});
    return (
    <IonList>
        <IonItem><b>{t("language")}</b></IonItem>
        {Object.entries(i18n_languages).map(([key, value])=>
            <IonItem key={key} lines="none" detail={false} button onClick={()=>changeLanguage(value.code_i18n)}>
            <IonLabel>{value.label}</IonLabel>
            <Flag code={value.code_flag_country} height="16"/>
        </IonItem>)
        }
    </IonList>);
}


const LanguageSelector: React.FC = () => {

    const { i18n } = useTranslation();

    const [present, dismiss] = useIonPopover(PopoverList, { changeLanguage: (language: string) => handleLanguageChange(language) });

    const handleLanguageChange = (language: string) => {
        i18n.changeLanguage(language).then(r => undefined)
        dismiss();
    }

    return <IonItem color="none" detail={false} button slot="end" lines="none" onClick={(e) => {
                present({
                    event: e.nativeEvent,
                })
            }}>
                <IonIcon icon={languageOutline} slot="end" />
            </IonItem>;
};

export default LanguageSelector;
