import React from "react";
import {IonChip, IonCol, IonDatetime, IonIcon, IonInput, IonItem, IonLabel, IonPopover, IonRow} from "@ionic/react";
import {calendarOutline, closeSharp, todayOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../utils/utils";


export type MultiDateSelectorInterface = {
    addCreationDate: (a:Date)=> void,
    delCreationDate: (a:number)=> void,
    creationDates: Date[],
    unique_key: string | number,
};

const MultipleDateSelector = ({addCreationDate, delCreationDate, creationDates, unique_key} : MultiDateSelectorInterface) => {

    const { t, i18n } = useTranslation(['common'], { keyPrefix: 'datetime' });

    return (
        <IonRow>
            <IonCol size-sm="4" size-md="4" size-lg="4">
                <IonItem button id={"open-multi-date-input-"+unique_key}>
                    <IonIcon slot="start" icon={calendarOutline} />
                    <IonLabel position="floating">{t("creation_date")}</IonLabel>
                    <IonInput type='text' value={formatDate(new Date(), i18n)}/>
                    <IonPopover trigger={"open-multi-date-input-"+unique_key} showBackdrop={false}>
                        <IonDatetime
                            presentation="date"
                            onIonChange={ev => addCreationDate(new Date(ev.detail.value as string))}
                            showDefaultButtons={true}
                        />
                    </IonPopover>
                </IonItem>
            </IonCol>
            <IonCol size-sm="8" size-md="8" size-lg="8">
                {creationDates.map((date, idx)=>{
                    return <IonChip key={idx}>
                        <IonIcon icon={todayOutline} color="primary"></IonIcon>
                        <IonLabel>{formatDate(date, i18n)}</IonLabel>
                        <IonIcon icon={closeSharp} onClick={(ev)=>delCreationDate(idx)}></IonIcon>
                    </IonChip>
                })}

            </IonCol>
        </IonRow>

    );
};

export default MultipleDateSelector;