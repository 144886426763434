import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import _ from "underscore";
import {IonProgressBar} from "@ionic/react";
import {CurveType} from 'recharts/types/shape/Curve';
import stc from "string-to-color";
import {formatDate, formatDatetimeQuery} from "../../utils/utils";

export type UseQueryResult<T> = {
    // Base query state
    originalArgs?: unknown // Arguments passed to the query
    data?: T // The latest returned result regardless of hook arg, if present
    currentData?: T // The latest returned result for the current hook arg, if present
    error?: unknown // Error result if present
    requestId?: string // A string generated by RTK Query
    endpointName?: string // The name of the given endpoint for the query
    startedTimeStamp?: number // Timestamp for when the query was initiated
    fulfilledTimeStamp?: number // Timestamp for when the query was completed

    // Derived request status booleans
    isUninitialized: boolean // Query has not started yet.
    isLoading: boolean // Query is currently loading for the first time. No data yet.
    isFetching: boolean // Query is currently fetching, but might have data from an earlier request.
    isSuccess: boolean // Query has data from a successful load.
    isError: boolean // Query is currently in an "error" state.

    refetch: () => void // A function to force refetch the query
}

export type CustonCurveType =
    | 'basis'
    | 'basisClosed'
    | 'basisOpen'
    | 'linear'
    | 'linearClosed'
    | 'natural'
    | 'monotoneX'
    | 'monotoneY'
    | 'monotone'
    | 'step'
    | 'stepBefore'
    | 'stepAfter';

export interface SimpleGraphicInterface {
    fc_queries: ((a:Record<string, string | number>, skip: Record<string, boolean>)=> UseQueryResult<any>)[],
    custom_filter: Record<string, string | number | Date | undefined | string[]>[],
    data_names: string[],
    line_type: string,
    names: (string | undefined)[],
    colors: string[],
    connect_nulls: boolean,
    tooltip_add?: string,
}

interface _SimpleGraphicInterface extends SimpleGraphicInterface {
    start_date: Date;
    end_date: Date,
    creation_dates : Date[][],
}

const SimpleGraphic = ({start_date, end_date, creation_dates, fc_queries, custom_filter,
                           data_names, line_type, names, colors, connect_nulls, tooltip_add} : _SimpleGraphicInterface) => {

    const [dataComputed, setDataComputed] = useState<any[]>([])
    const [skip, setSkip] = useState<boolean>(false)
    const { i18n } = useTranslation(['common'], { keyPrefix: 'datetime' });

    let start_query = formatDatetimeQuery(start_date)
    let end_query = formatDatetimeQuery(end_date)
    let creation_dates_query = creation_dates.map(dates => dates.map(date => formatDatetimeQuery(date, true, "isoDate")))

    const datas = fc_queries.map(
        (fc_query, index): UseQueryResult<any>=>{
            let filter_creation_dates = {}
            if(creation_dates_query[index].length > 0){
                filter_creation_dates = {'creation_date__in': creation_dates_query}
            }

            return fc_query({...{'horodate__gte': start_query,
                    'horodate__lte': end_query}, ...custom_filter[index], ...filter_creation_dates },{skip,})
        });

    useEffect(() => {
        let data_formatted: any = {};
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        let condition = datas.every(({data, isLoading, isFetching})=>!(isLoading || isFetching) && data)
        if(condition && !skip){
            setSkip(true)
            datas.map((useQueryResult, index: number) => {
                let _ = useQueryResult.data ? useQueryResult.data.map((data: any, _: number) => {
                    let _dt:any = {}
                    let horodate = new Date(data.horodate).getTime() //recharts_date_formatter(data.horodate)
                    let custom_name = data.creation_date || index.toString()
                    _dt[custom_name] = parseFloat(data[data_names[index]])
                    _dt['horodate'] = horodate
                    _dt['original_horodate'] = data.horodate
                    _dt['sort'] = new Date(data.horodate)
                    if(tooltip_add !== undefined){
                        _dt[tooltip_add] = data[tooltip_add]
                    }
                    if(horodate in data_formatted){
                        data_formatted[horodate] = {..._dt, ...data_formatted[horodate]}

                    }else{
                        data_formatted[horodate] = _dt
                    }
                    return undefined
                }) : undefined
            })
            data_formatted = _.values(data_formatted)

            const sortedProcess = data_formatted.sort((a:any, b:any) => a.sort - b.sort )
            setDataComputed(sortedProcess)
        }
    }, [datas]);

    useEffect(() => {
        setSkip(false)
    }, [start_date, end_date, creation_dates]);


    return (
        <>
            {datas.every(({data, isLoading, isFetching})=>(isLoading || isFetching)) ? <IonProgressBar type="indeterminate"/> : undefined}
            <ResponsiveContainer width="100%" height={500}>
                <ComposedChart
                    data={dataComputed}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="horodate" scale={'time'} interval="preserveStartEnd" tickFormatter={(value, index)=> formatDate(value, i18n, true)}/>
                    <YAxis domain={['auto', 'auto']}/>
                    <Tooltip
                        labelFormatter={t => formatDate(t, i18n, true)}
                        formatter={(value, name, props) => {
                            if(tooltip_add !== undefined && props.payload[tooltip_add]){
                                if(props.payload[tooltip_add].length>0){
                                    return value + ' | ' + props.payload[tooltip_add]
                                }
                            }
                            return value
                        }}
                    />
                    <Legend />
                    {names.map((name, index)=>{
                        return name ? <Line connectNulls={connect_nulls} key={index} name={name} type={line_type as CurveType} strokeWidth={2} stroke={colors[index]} dataKey={index} activeDot={{ onClick: (event, payload) => console.log(payload) }} dot={false} legendType="circle"/> : undefined
                    })}
                    {creation_dates_query.map((multi_dates, index)=>{
                        return multi_dates.map((date, idx)=>{
                            return <Line connectNulls={connect_nulls} key={date} type={line_type as CurveType} stroke={stc(date)} strokeWidth={2} dataKey={date} dot={true} activeDot={{ onClick: (event, payload) => console.log(payload) }} legendType="circle"/>
                        })
                    })}
                </ComposedChart>
            </ResponsiveContainer>
        </>
    );
};

export default SimpleGraphic;