import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import "./Page404.css"
import {sadOutline} from "ionicons/icons";

const Page404: React.FC = () => {

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Page doesn't exist</IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton default-href="/page/home"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className="loading-ion-grid">
                    <IonRow>
                        <IonCol>
                            <IonIcon className="ion-icon-404" icon={sadOutline}/>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Page404;
