import {IonCol, IonRow, IonSkeletonText} from "@ionic/react";
import React from "react";


const LoadingHistoryTable: React.FC = () => {

    return (
        <IonRow>
            <IonCol size="4">
                <IonSkeletonText animated style={{ width: '100%' }} />
            </IonCol>
            <IonCol size="6" >
                <IonSkeletonText animated style={{ width: '100%' }} />
            </IonCol>
            <IonCol size="2">
                <IonSkeletonText animated style={{ width: '100%' }} />
            </IonCol>
        </IonRow>
    );
};

export default LoadingHistoryTable;