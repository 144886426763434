import {
    IonAvatar,
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonNote,
    IonRow,
    IonSpinner
} from "@ionic/react";
import {eyeOffOutline, eyeOutline, refresh} from "ionicons/icons";
import {useState} from "react";
import {User} from "../../types/types";
import {useUpdateUserMutation} from "../../services/user-api";
import {genUrlGravatar} from "../../utils/utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomIonInput from "../signup/CustomIonInput";
import {useDispatch} from "react-redux";
import {setToastMessage} from "../../slices/message.slice";
import {useTranslation} from "react-i18next";

const schema = yup.object({
    first_name: yup.string().max(150, 'Maximum 150 characters').required(),
    last_name: yup.string().max(150, 'Maximum 150 characters').required(),
    password: yup.string().min(8, 'Minimum 8 characters').max(20, 'Maximum 6 characters').optional(),
    passwordConfirmation: yup.string().when("password", {
        is: (value: string) => value !== undefined,
        then: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required()
    }),
}).required();

const Profile: React.FC<{user: User}> = ({user}) => {

    const dispatch = useDispatch()
    const [
        updateUser, // This is the mutation trigger
        { isLoading: isUpdating }, // This is the destructured mutation result
    ] = useUpdateUserMutation()
    const { t } = useTranslation(['translation', 'common']);

    const [ showPassword, setshowPassword ] = useState(false)
    const defaultValues = {
        first_name: user.first_name,
        last_name: user.last_name,
        password: undefined,
        passwordConfirmation: undefined,
    }

    const { handleSubmit, formState: { errors }, control  } = useForm<Partial<User>>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: defaultValues
    });

    const submit = (data:Partial<User>) => {
        updateUser(data).unwrap().then(()=>{
            dispatch(setToastMessage({message: "Update - OK", color: "success"}))
        }).catch((error)=>{
            dispatch(setToastMessage({message: "Something went wrong !", color: "warning"}))
        });
    }

    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonAvatar style={{margin: "auto", width:"100px", height:"100px"}}>
                        <IonImg alt="avatar" src={genUrlGravatar(user.email, 100)} />
                    </IonAvatar>
                </IonCol>
            </IonRow>
            <form onSubmit={handleSubmit(submit)}>
                <IonItemGroup>
                    <IonItem lines="none">
                        <IonLabel><strong>{t('translation:settings.profile')}</strong></IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">{t('translation:settings.first_name')}</IonLabel>
                        <CustomIonInput name="first_name" disabled={isUpdating} type="text" control={control} />
                        <IonNote style={{color:"red"}} slot="error">{errors.first_name?.message}</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">{t('translation:settings.last_name')}</IonLabel>
                        <CustomIonInput name="last_name" disabled={isUpdating} type="text" control={control} />
                        <IonNote style={{color:"red"}} slot="error">{errors.last_name?.message}</IonNote>
                    </IonItem>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="floating">{t('translation:settings.password')}</IonLabel>
                                <CustomIonInput name="password" disabled={isUpdating} type={showPassword ? "text" : "password"} control={control} />
                                <IonIcon slot="end" icon={showPassword ? eyeOutline : eyeOffOutline} onClick={()=>setshowPassword(!showPassword)}/>
                                <IonNote style={{color:"red"}} slot="error">{errors.password?.message}</IonNote>
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="floating">{t('translation:settings.repeat_password')}</IonLabel>
                                <CustomIonInput name="passwordConfirmation" disabled={isUpdating} type="password" control={control} />
                                <IonNote style={{color:"red"}} slot="error">{errors.passwordConfirmation?.message}</IonNote>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton disabled={isUpdating} type="submit" expand="block" >
                                {t('common:update')}
                                {isUpdating ? <IonSpinner name="lines-small" slot="end"/> : <IonIcon slot="end" icon={refresh} />}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonItemGroup>
                <IonItemGroup>
                    <IonItem lines="none">
                        <IonLabel><strong>{t('translation:settings.contact')}</strong></IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">{t('translation:settings.email')}</IonLabel>
                        <IonInput type="email" disabled={true} value={user.email}/>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">{t('translation:settings.phone_number')}</IonLabel>
                        <IonInput type="tel" disabled={true} value={user.phone_number}/>
                    </IonItem>
                </IonItemGroup>
            </form>
        </IonGrid>

    );
};

export default Profile;