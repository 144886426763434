import {IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import React from "react";
import {useTranslation} from "react-i18next";
import SimpleGraphicDateSelector from "../../components/home/SimpleGraphicDateSelector";
import {useGetDayaheadPriceQuery, useGetImbalancePriceQuery} from "../../services/data-api";


const Dayahead: React.FC = () => {
    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'dayahead' });

    const GRAPHICS = [
        {
            label:t("title"),
            unique_key: "market_prices",
            fc_queries:[useGetDayaheadPriceQuery, useGetImbalancePriceQuery, useGetImbalancePriceQuery],
            custom_filter:[{}],
            data_names:['valeur', 'pre_pos', 'pre_neg'],
            line_type: 'stepAfter',
            names:[t("dayahead"), t("pre_pos"), t("pre_neg")],
            connect_nulls:true,
            active_multi_date_selector: [false, false, false],
            colors:['#540D6E', '#FF22AA', '#33BBAA']
        },
    ]

    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        {GRAPHICS.map((props, idx)=>{
                            return <SimpleGraphicDateSelector key={idx} {...props}/>
                        })}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>

    );
};


export default Dayahead;
