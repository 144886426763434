import {IonButton, IonCol, IonIcon, IonItem, IonRow} from "@ionic/react";
import React from "react";
import {chevronBack, chevronForward, playBack, playForward} from "ionicons/icons";


type PaginationProps = {
    startCallback: () => void;
    backCallback: () => void;
    nextCallback: () => void;
    endCallback: () => void;
    page: number;
    total: number;
};

const Pagination = ({startCallback, backCallback, nextCallback, endCallback, page, total} : PaginationProps) => {

    return (
        <IonRow className="ion-row-custom">
            <IonCol className="ion-col-custom" size="2">
                <IonItem lines="none" >
                    <IonButton slot="start" fill="clear" onClick={startCallback}>
                        <IonIcon slot="icon-only" icon={playBack}/>
                    </IonButton>
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="2">
                <IonItem lines="none" >
                    <IonButton slot="start" fill="clear" onClick={backCallback}>
                        <IonIcon slot="icon-only" icon={chevronBack}/>
                    </IonButton>
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="4">
                <IonItem lines="none" >
                    {page} / {total}
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="2">
                <IonItem lines="none" >
                    <IonButton slot="start" fill="clear" onClick={nextCallback}>
                        <IonIcon slot="icon-only" icon={chevronForward}/>
                    </IonButton>
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="2">
                <IonItem lines="none" >
                    <IonButton slot="start" fill="clear" onClick={endCallback}>
                        <IonIcon slot="icon-only" icon={playForward}/>
                    </IonButton>
                </IonItem>
            </IonCol>
        </IonRow>
    );
};

export default Pagination;