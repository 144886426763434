import {IonAvatar, IonIcon, IonImg, IonItem, IonLabel, IonList, IonSkeletonText, useIonPopover} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import "./Avatar.css"
import {genUrlGravatar, getInitials} from "../../utils/utils";
import {logOutOutline} from "ionicons/icons";
import {removeAccessToken} from "../../slices/auth/auth.slice";
import {useGetUserQuery} from "../../services/user-api";
import {User} from "../../types/types";
import {useTranslation} from "react-i18next";

const PopoverList: React.FC<{user: User | undefined; logout: () => void;}> = ({ user, logout }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'header' });
    return (
    <IonList>
        <IonItem><b>{user ? user.first_name + ' ' + user.last_name : "Guest"}</b></IonItem>
        <IonItem lines="none" detail={false} button onClick={logout}>
            <IonLabel>{t('logout')}</IonLabel>
            <IonIcon icon={logOutOutline} slot="start"/>
        </IonItem>
    </IonList>);
}


const Avatar: React.FC = () => {

    const auth = useSelector((state: RootState) => state.auth)
    const { data, isLoading } = useGetUserQuery(auth.user_email)
    const dispatch = useDispatch();

    const [present, dismiss] = useIonPopover(PopoverList, { user: data, logout: () => handleLogout() });

    const handleLogout = async () => {
        // Il faut attendre que le popover soit "dismiss" : IMPORTANT
        await dismiss();
        // Suppression du token
        dispatch(removeAccessToken());
    }
    return (!data && isLoading ) ?
        (<IonItem slot="end" lines="none">
            <IonLabel>
                <IonSkeletonText animated style={{ width: '50px' }} />
            </IonLabel>
            <IonAvatar slot="end" className="custom-ion-avatar">
                <IonSkeletonText animated />
            </IonAvatar>
        </IonItem>)
        :(
            <IonItem color="none" detail={false} button slot="end" lines="none" onClick={(e) => {
                present({
                    event: e.nativeEvent,
                })
            }}>
                <IonLabel>
                    {data ? getInitials(data.first_name) + getInitials(data.last_name): "Guest"}
                </IonLabel>
                <IonAvatar slot="end" className="custom-ion-avatar">
                    <IonImg alt="avatar" src={genUrlGravatar(data ? data.email : undefined)} />
                </IonAvatar>
            </IonItem>
        );
};

export default Avatar;
