import {
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText
} from "@ionic/react";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {calendarOutline, funnelOutline, todayOutline} from "ionicons/icons";
import {useSelector} from "react-redux";
import {addDays, formatDate} from "../../utils/utils";
import {ProductCode, Profil, SousProfil} from "../../types/types";
import {RootState} from "../../store";
import Graphic from "../../components/dynamic_profiles/Graphic";
import {useGetUserQuery, useListContractProductQuery} from "../../services/user-api";

const COLORS = [ "#04052E", "#062726", "#102B3F", "#aa8b47", "#4C3B4D", "#22007C", "#AA8B47FF"]

const DynamicProfiles: React.FC = () => {
    const [startDate, setStartDate] = useState<Date>(addDays(new Date(), -7));
    const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 4));
    const [selectedProduct, setSelectedProduct] = useState<ProductCode>(ProductCode.NONE);
    const [generationDate, setGenerationDate] = useState<Date>(new Date());
    const [profils, setProfils] = useState<Profil[]>([Profil.PRO1, Profil.PRO2]);
    const [sousProfils, setSousProfils] = useState<SousProfil[]>([SousProfil.BASE, SousProfil.HP]);
    const products_code = [ProductCode.J1, ProductCode.J3, ProductCode.J4, ProductCode.J7, ProductCode.J9, ProductCode.LONG, ProductCode.EXTREM]

    const auth = useSelector((state: RootState) => state.auth)
    const { data: user, isLoading: isLoadingUser } = useGetUserQuery(auth.user_email)
    const { data: contractProducts, isLoading: isLoadingContratProducts } = useListContractProductQuery((!isLoadingUser && user) ? {contract_fk : user.contract_fk, product_code__in: products_code}: {}, {'skip': !user,})

    const { t, i18n } = useTranslation(['translation', 'common']);

    useEffect(()=>{
        setSelectedProduct(contractProducts && contractProducts.length > 0 ? contractProducts[0].product_code : ProductCode.NONE)
    }, [contractProducts])

    return (
        <IonContent>
            <IonProgressBar hidden={!isLoadingUser && !isLoadingContratProducts} type="indeterminate"/>
            <IonGrid>
                <IonRow className="ion-justify-content-center no-border">
                    <IonCol className="no-border" size-sm="12" size-md="8" size-lg="7">
                        <form>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem button id="open-start-date-input">
                                        <IonIcon slot="start" icon={calendarOutline} />
                                        <IonLabel position="floating">{t("datetime.start_date", { ns: 'common' })}</IonLabel>
                                        <IonInput type='text' value={formatDate(startDate, i18n)}/>
                                        <IonPopover trigger="open-start-date-input" showBackdrop={false}>
                                            <IonDatetime
                                                presentation="date"
                                                onIonChange={ev => setStartDate(new Date(ev.detail.value as string))}
                                                showDefaultButtons={true}
                                            />
                                        </IonPopover>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem button id="open-end-date-input">
                                        <IonIcon slot="start" icon={calendarOutline} />
                                        <IonLabel position="floating">{t("datetime.end_date", { ns: 'common' })}</IonLabel>
                                        <IonInput type='text' value={formatDate(endDate, i18n)}/>
                                        <IonPopover trigger="open-end-date-input" showBackdrop={false}>
                                            <IonDatetime
                                                presentation="date"
                                                onIonChange={ev => setEndDate(new Date(ev.detail.value as string))}
                                                showDefaultButtons={true}
                                            />
                                        </IonPopover>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem button id="open-generation_date-date-input">
                                        <IonIcon slot="start" icon={todayOutline} />
                                        <IonLabel position="floating">{t("datetime.creation_date", { ns: 'common' })}</IonLabel>
                                        <IonInput type='text' value={formatDate(generationDate, i18n)}/>
                                        <IonPopover trigger="open-generation_date-date-input" showBackdrop={false}>
                                            <IonDatetime
                                                presentation="date"
                                                onIonChange={ev => setGenerationDate(new Date(ev.detail.value as string))}
                                                showDefaultButtons={true}
                                            />
                                        </IonPopover>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem button>
                                        <IonIcon slot="start" icon={funnelOutline} />
                                        <IonLabel position="floating">{t("product")}</IonLabel>
                                        <IonSelect value={selectedProduct} multiple={false} placeholder={t("dynamic_profiles.select_contracts", { ns: 'translation' })} onIonChange={e => setSelectedProduct(e.detail.value)}>
                                            {contractProducts ? contractProducts.map((cp, idx)=>{return (<IonSelectOption key={idx} value={cp.product_code}>{cp.product_code}</IonSelectOption>)}): undefined}
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem>
                                        <IonIcon slot="start" icon={funnelOutline} />
                                        <IonLabel>{t("profiles")}</IonLabel>
                                        <IonSelect value={profils} multiple={true} placeholder={t("dynamic_profiles.select_profiles", { ns: 'translation' })} onIonChange={e => setProfils(e.detail.value)}>
                                            {Object.keys(Profil).map((value, idx)=>{return (<IonSelectOption key={idx} value={value}>{Profil[value as keyof typeof Profil]}</IonSelectOption>)})}
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem>
                                        <IonIcon slot="start" icon={funnelOutline} />
                                        <IonLabel>{t("sub_profiles")}</IonLabel>
                                        <IonSelect value={sousProfils} multiple={true} placeholder={t("dynamic_profiles.select_sub_profiles", { ns: 'translation' })} onIonChange={e => setSousProfils(e.detail.value)}>
                                            {Object.keys(SousProfil).map((value, idx)=>{return (<IonSelectOption key={idx} value={value}>{SousProfil[value as keyof typeof SousProfil]}</IonSelectOption>)})}
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            {!isLoadingUser && !isLoadingContratProducts ? profils.map((profil, idx)=>{
                                return (
                                    <IonRow key={idx}>
                                        <IonText color="dark">
                                            <h3>{profil}</h3>
                                        </IonText>
                                        <IonCol size="12">
                                            <Graphic start_date={startDate} end_date={endDate} generation_date={generationDate} product={selectedProduct} profil={profil} sous_profils={sousProfils} color={COLORS[idx]} />
                                        </IonCol>
                                    </IonRow>
                                )
                            }): <p>{t("data_is_loading")}...</p>}
                        </form>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    );
};

export default DynamicProfiles;