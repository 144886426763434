import {IonAlert, IonButton, IonCol, IonGrid, IonIcon, IonLoading, IonRow, IonSpinner} from "@ionic/react";
import {trashOutline} from "ionicons/icons";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {removeAccessToken} from "../../slices/auth/auth.slice";
import {useDeleteUserMutation} from "../../services/user-api";
import {setToastMessage} from "../../slices/message.slice";
import {useTranslation} from "react-i18next";

const DeleteAccount: React.FC = () => {

    const dispatch = useDispatch()
    const [showAlert, setShowAlert] = useState(false);
    const [
        deleteUser, // This is the mutation trigger
        { isLoading: isDeleting }, // This is the destructured mutation result
    ] = useDeleteUserMutation()
    const { t } = useTranslation('translation', { keyPrefix: 'settings' });

    return (
        <IonGrid>
            <IonLoading
                isOpen={isDeleting}
                message={'Please wait...'}
                duration={5000}
            />
            <IonAlert
                isOpen={showAlert}
                header={'Delete your account'}
                message={'Are you sure to delete definitively your account ?'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        id: 'cancel-button',
                        handler: () => {
                            setShowAlert(false);
                        }
                    },
                    {
                        text: 'Delete',
                        id: 'confirm-button',
                        cssClass: 'danger',
                        handler: () => {
                            // delete definitively account
                            deleteUser().unwrap().then(()=>{
                                dispatch(setToastMessage({message: "Delete success", color: "success"}))
                                dispatch(removeAccessToken())
                            }).catch((error)=>{
                                dispatch(setToastMessage({message: "Something went wrong !", color: "warning"}))
                            })
                        }
                    }
                ]}
            />
            <IonRow>
                <IonCol>
                    <IonButton color="danger" type="button" expand="block" onClick={()=>setShowAlert(true)} >
                        {t('delete_account')}
                        {isDeleting ? <IonSpinner name="lines-small" slot="end"/> : <IonIcon slot="end" icon={trashOutline} />}
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>

    );
};

export default DeleteAccount;