import {
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonSpinner
} from "@ionic/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {addDays} from "../utils/utils";
import DateSelector from "../components/home/DateSelector";
import PlotlyGraph from "../components/plotly/PlotlyGraph";
import {useGetPlotlyErrorCurvesQuery} from "../services/profilage-api";
import {Profil, SousProfil} from "../types/types";
import dateFormat from "dateformat";
import moment from 'moment'
import {funnelOutline} from "ionicons/icons";

const Analysis: React.FC = () => {
    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'dynamic_profiles' });

    const [startDate, setStartDate] = useState<Date>(addDays(new Date(), -15));
    const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 1));
    const [profils, setProfils] = useState<Profil[]>([Profil.PRO1]);

    let generated_from = dateFormat(moment(startDate).local().startOf('day').toDate(), "isoDate")
    let generated_to = dateFormat(moment(endDate).local().startOf('day').toDate(), "isoDate")

    const {data, isLoading, isFetching} = useGetPlotlyErrorCurvesQuery({"generated_from": generated_from,
        "generated_to": generated_to,
        "list_profil": profils})

    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <DateSelector unique_key={'analysis'} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <IonItem>
                            <IonIcon slot="start" icon={funnelOutline} />
                            <IonLabel>{t("profiles")}</IonLabel>
                            <IonSelect value={profils} multiple={true} placeholder={t("dynamic_profiles.select_profiles", { ns: 'translation' })} onIonChange={e => {
                                console.log(e.detail.value)
                                setProfils(e.detail.value)}}>
                                {Object.keys(Profil).map((value, idx)=>{return (<IonSelectOption key={idx} value={value}>{Profil[value as keyof typeof Profil]}</IonSelectOption>)})}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    {isLoading || isFetching ?
                        <IonItem lines={'none'}>
                            <IonLabel>Loading graphic </IonLabel>
                            <IonSpinner name="bubbles"></IonSpinner>
                        </IonItem> : data?.map((graph, idx) => {
                            return (<IonCol key={idx} size="12" style={{"height": "600px"}}>
                                <PlotlyGraph html={graph}/>
                            </IonCol>)
                        })
                    }
                </IonRow>
            </IonGrid>

        </IonContent>

    );
};


export default Analysis;
