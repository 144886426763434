import React from "react";
import {IonCard, IonCardContent, IonIcon, IonItem, IonLabel} from "@ionic/react";
import {GenerationUnitType} from "../../../types/types";
import CustomMap from "../CustomMap";
import './VppProductionUnit.css'
import UnavailabilityGenerationUnit from "./UnavailabilityGenerationUnit";
import {locateOutline} from "ionicons/icons";
import GenerationUnitParameters from "./GenerationUnitParameters";

const GenerationUnit = (props: GenerationUnitType) => {
    return (
        <IonCard class="ion-card-production-unit">
            <IonItem color="success">
                <IonIcon icon={locateOutline} slot="start" size='large'/>
                <IonLabel className="ion-text-wrap" ><b>{props.id} -- {props.reference}</b></IonLabel>
            </IonItem>
            <CustomMap positions={[[props.latitude, props.longitude]]} zoom={17} messages={[]}/>

            <IonCardContent>
                <GenerationUnitParameters {...props}/>
                <UnavailabilityGenerationUnit id={props.id}/>
            </IonCardContent>
        </IonCard>
    );
};

export default GenerationUnit;