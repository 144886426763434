import React from "react";
import {useController, UseControllerProps} from "react-hook-form";
import {IonInput} from "@ionic/react";

interface CustomProps {
   type: "text" | "password"| "email"| "number"| "search"| "tel"| "url";
   formatter: ((v:string)=>string) | undefined;
}
type FormValues = {
  name: string;
};
type CustomUseControllerProps = UseControllerProps<FormValues> & CustomProps


const CustomIonInput: React.FC<any> = (props: CustomUseControllerProps) => {
  const { field } = useController(props);
  return (
      <IonInput value={field.value}
                onIonBlur={field.onBlur}
                onIonChange={field.onChange}
                {...props}
      />
  );
}

export default CustomIonInput;
