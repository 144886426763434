import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonItem,
    IonLabel,
    IonNote,
    IonProgressBar,
    IonThumbnail
} from "@ionic/react";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone'
import {useUploadProfilesFileMutation, useUploadTlrFileMutation} from "../services/profiles-api";
import {AxiosProgressEvent} from "axios";
import RunEngineTable from "../components/cdc_engine/RunEngineTable";
import {read} from 'xlsx';
import {faCircleNodes} from "@fortawesome/free-solid-svg-icons";

const CdcEngine: React.FC = () => {
    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'cdc_engine' });
    const [uploadProfileFile, {isLoading: isLoadingProf}] = useUploadProfilesFileMutation();
    const [uploadTlrFile, {isLoading: isLoadingTlr}] = useUploadTlrFileMutation();
    const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
    const [uploadPercentage, setUploadPercentage] = useState<number | undefined>(undefined);

    const onUploadProgress = (e:AxiosProgressEvent) => {
        setUploadPercentage(e.progress)
    }

    const fileObjectToBinary = (fileObj: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(fileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleSubmit = async (
        acceptedFiles: File[],
        fileRejections: FileRejection[],
        event: DropEvent
    ) => {
        setCurrentFile(acceptedFiles[0])
        let formdata = new FormData();
        formdata.append("file", acceptedFiles[0]);
        let wb = read(await fileObjectToBinary(acceptedFiles[0]), {type:'binary', bookSheets: true})
        let uploadFile = uploadProfileFile
        if(wb.SheetNames.includes("courbes_de_charge")){
            uploadFile = uploadTlrFile
        }
        const response = await uploadFile({data:formdata,
            filename:acceptedFiles[0].name,
            onUploadProgress:onUploadProgress}).then((response) => {
            setCurrentFile(undefined)
        })
    }

    const {getRootProps,
        getInputProps,
        open,
        fileRejections,
        acceptedFiles} = useDropzone(
        {
            noClick: true,
            accept: {
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/x-excel': ['.xls'],
                'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
            },
            onDrop: handleSubmit
        })

    return (
        <IonContent class="ion-padding">
            <IonCard style={{minWidth: "900px"}}>
                <IonItem color="primary">
                    <IonThumbnail slot="start" style={{"height": "100%"}}>
                        <FontAwesomeIcon icon={faCircleNodes} size={"2x"}/>
                    </IonThumbnail>
                    <IonLabel className="ion-text-wrap" ><b>{t('title')}</b></IonLabel>
                </IonItem>

                <IonCardContent>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <IonButton onClick={open}>
                            {t('upload_file')}
                        </IonButton>
                    </div>
                    {currentFile ?
                        <IonItem lines={"none"}>
                            <IonNote slot="start">{Math.round(currentFile.size / 1000)} ko</IonNote>
                            <IonLabel>{currentFile.name}</IonLabel>
                            <IonProgressBar style={{"width": "30%", "height": "50%"}} slot="end" value={uploadPercentage}></IonProgressBar>
                        </IonItem>
                        : undefined}
                    <br/>
                    <RunEngineTable />
                </IonCardContent>
            </IonCard>
        </IonContent>
    );
};


export default CdcEngine;
