import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ToastMessage} from "../types/types";

const initialState: ToastMessage = {
  message: "",
  color: "medium"
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setToastMessage: (state, action: PayloadAction<ToastMessage>) => {
      return action.payload;
    },
    clearToastMessage: () => {
      return initialState;
    },
  },
});

const { reducer, actions } = messageSlice;

export const { setToastMessage, clearToastMessage } = actions
export const messageReducer = reducer;