export const routes = {
  login : "/auth/login",
  signup: "/auth/signup",
  non_existing: "/404",
  policy: "/auth/policy",
  home: "/page/home",
  cdc_engine: "/page/cdc_engine",
  dynamic_profiles_history: "/page/dynamic_profiles_history",
  dynamic_profiles: "/page/dynamic_profiles",
  nuclear: "/page/nuclear",
  temperatures: "/page/temperatures",
  windpower: "/page/windpower",
  solarpower: "/page/solarpower",
  productionunit: "/productionunit",
  generationunit: "/page/generationunit",
  dayahead: "/page/dayahead",
  analysis: "/page/analysis",
  settings : "/page/settings",
}