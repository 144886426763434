import React from "react";
import './VppProductionUnit.css'
import {addDays, formatDate, formatDatetimeQuery} from "../../../utils/utils";
import {useGetForecastUnitQuery} from "../../../services/vpp-api";
import {Area, AreaChart, CartesianGrid, ReferenceLine, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {useTranslation} from "react-i18next";

const VppUnitChart = ({id, model, pmax}:{id: number, model: string, pmax: number}) => {

    const { i18n } = useTranslation();
    const { data } = useGetForecastUnitQuery({model: model,
        object_id: id,
        horodate__gte: formatDatetimeQuery(new Date()),
        horodate__lte: formatDatetimeQuery(addDays(new Date(), 7))})

    const formatXAxis = (value: any, index: number):string => {
        return i18n.t('common:intlDateTime', {
            val: Date.parse(value),
            formatParams: {
                val: {month: 'numeric', day: 'numeric'},
            }
        })
    }

    return (
        <ResponsiveContainer width="100%" height={150} >
            <AreaChart height={150} width={100} data={data} margin={{ top: 10, right: 26, left: 25, bottom: 0 }} >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="40%" stopColor="#2dd36f" stopOpacity={1}/>
                        <stop offset="100%" stopColor="#3880ff" stopOpacity={1}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="horodate" interval={23} hide={false} tickFormatter={formatXAxis}/>
                <CartesianGrid strokeDasharray="4 4" />
                <YAxis tickCount={0} domain={[0, pmax]} hide/>
                <ReferenceLine y={pmax} label="Max" stroke="red" strokeWidth={3}/>
                <Area type="monotone" dataKey="power" stroke="url(#colorUv)" strokeWidth={1} dot={false} fillOpacity={0.8}  fill="url(#colorUv)"/>
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default VppUnitChart;