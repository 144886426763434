import {IonButton, IonChip, IonCol, IonItem, IonLabel, IonRow, IonSpinner} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {RunInput, RunInputStatusEnum, RunOutput} from "../../types/types";
import {formatDate, getBaseUrl} from "../../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import '../utils/table.css'
import {faCircleInfo, faCloudArrowDown, faFileWaveform, faWaveSquare} from "@fortawesome/free-solid-svg-icons";

type RowProps = {
    runInput: RunInput;
    runOutput?: RunOutput;
};

const Row = ({runInput, runOutput}: RowProps) => {
    const {t, i18n} = useTranslation(['translation', 'common']);

    const findColor = (state: RunInputStatusEnum) => {
        // "INIT" | "STEP_PARSING" | "STEP_API" | "STEP_CLUSTER" | "STEP_MAP" | "STEP_AGGREGATION" | "STEP_SAVE" | "SUCCESS" | "FAILED"
        let color = "primary"
        if(state === "INIT"){
            color = "primary"
        }else if((state !== "SUCCESS") && (state !== "FAILED")){
            color = "warning"
        }else if(state === "SUCCESS"){
            color = "success"
        }else if(state === "FAILED"){
            color = "danger"
        }
        return color
    }

    return (
        <IonRow className="ion-row-custom">
            <IonCol className="ion-col-custom" size="1">
                <IonItem lines="none" class="data-label">
                    <IonLabel class="ion-text-wrap">{runInput.demand_id}</IonLabel>
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="2">
                <IonItem lines="none">
                    {formatDate(runInput.creation_datetime, i18n, true)}
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="1">
                <IonItem lines="none">
                    <IonChip color={runInput.demand_type === "TLR" ? "secondary" : "success"}>{runInput.demand_type || "PROFILE"}</IonChip>
                </IonItem>
            </IonCol>
            <IonCol className="ion-col-custom" size="1">
                <IonItem lines="none">
                    <IonLabel class="ion-text-wrap">{runInput.input_type}</IonLabel>
                </IonItem>
            </IonCol>

            <IonCol className="ion-col-custom" size="1.5">
                <IonItem lines="none">
                    <IonButton slot="start" fill="clear" href={process.env.NODE_ENV === 'production' ? runInput.file: getBaseUrl() + runInput.file}>
                        <FontAwesomeIcon icon={faCloudArrowDown} size={"2x"}/>
                    </IonButton>
                </IonItem>
            </IonCol>

            <IonCol className="ion-col-custom" size="3.25">
                <IonItem lines="none">
                    <IonLabel class="ion-text-wrap">{runOutput? formatDate(runOutput.creation_datetime, i18n, true): undefined}</IonLabel>
                </IonItem>
            </IonCol>

            <IonCol className="ion-col-custom" size="2.25">
                {runOutput?
                    <IonItem lines="none">
                        {runOutput.file_loadcurve ?
                            <IonButton fill="clear" href={process.env.NODE_ENV === 'production' ? runOutput.file_loadcurve: getBaseUrl() + runOutput.file_loadcurve}>
                                <FontAwesomeIcon color="#0000FF" icon={faWaveSquare} size={"2x"}/>
                            </IonButton> : undefined}
                        {runOutput.file_metadata ?
                            <IonButton fill="clear" href={process.env.NODE_ENV === 'production' ? runOutput.file_metadata: getBaseUrl() + runOutput.file_metadata}>
                                <FontAwesomeIcon color="#000000" icon={faCircleInfo} size={"2x"}/>
                            </IonButton> : undefined}
                        {runOutput.file_logs ?
                            <IonButton fill="clear" href={process.env.NODE_ENV === 'production' ? runOutput.file_logs: getBaseUrl() + runOutput.file_logs}>
                                <FontAwesomeIcon color="#FF0000" icon={faFileWaveform} size={"2x"}/>
                            </IonButton> : undefined}
                    </IonItem>
                    : <IonItem lines="none" color={findColor(runInput.status)}>
                        <IonLabel>{runInput.status}</IonLabel>
                        {(runInput.status !== "FAILED") && (runInput.status !== "SUCCESS") ?
                            <IonSpinner name="dots" slot="end" color="light"/> : undefined}
                    </IonItem>}
            </IonCol>
        </IonRow>
    );
};

export default Row;