import React, {useState} from "react";
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonRow,
    IonSkeletonText
} from "@ionic/react";
import {useTranslation} from "react-i18next";
import './VppProductionUnit.css'
import {locateOutline} from "ionicons/icons";
import {routes} from "../../../routes";
import {useLocation} from "react-router";
import CustomMap from "../CustomMap";
import {VppProductionUnitType} from "../../../types/types";
import DateSelector from "../../home/DateSelector";
import {useGetListGenerationUnitQuery} from "../../../services/vpp-api";
import {LatLngTuple} from "leaflet";
import VppProductionUnitParameters from "./VppProductionUnitParameters";
import VppUnitChart from "./VppProductionUnitChart";

const VppProductionUnit = ({vppProductionUnit, isLoading}: {vppProductionUnit: VppProductionUnitType, isLoading: boolean}) => {

    const { data: gus, isLoading: guLoading } = useGetListGenerationUnitQuery({power_unit_fk: vppProductionUnit.id})

    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'windpower' });
    const location = useLocation();
    const [startDate, setStartDate] = useState<Date>(new Date(vppProductionUnit.start_date));
    const [endDate, setEndDate] = useState<Date | undefined>(vppProductionUnit.end_date ? new Date(vppProductionUnit.end_date) : undefined);
    return (
        <IonCard class="ion-card-production-unit">
            <IonItem color="primary">
                <IonIcon icon={locateOutline} slot="start" size='large'/>
                <IonLabel className="ion-text-wrap" ><b>{vppProductionUnit.name}</b></IonLabel>
            </IonItem>
            <IonRow>
                {isLoading || guLoading ?
                    <IonSkeletonText animated={true} style={{ 'height': '300px', 'width': '100%' }}></IonSkeletonText>:
                    gus ? <CustomMap positions={gus.length > 0 ? gus.reduce((positions, gu) => [...positions, [gu.latitude, gu.longitude]], [] as LatLngTuple[]): []} messages={[]}/> : undefined
                }
            </IonRow>
            <IonRow>
                <VppUnitChart id={vppProductionUnit.id} model={'VppProductionUnit'} pmax={vppProductionUnit.nominal_power}/>
            </IonRow>

            <IonCardContent>
                <VppProductionUnitParameters {...vppProductionUnit}/>

                <IonRow>
                    <IonItemDivider>
                        <IonLabel color={"dark"}>
                            <b>{t('integration_date')}</b>
                        </IonLabel>
                    </IonItemDivider>
                </IonRow>
                {isLoading ?
                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>:
                    <DateSelector disabled={true} unique_key={"prod_unit"+vppProductionUnit.id} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate}/>
                }

            </IonCardContent>

            <IonGrid>
                <IonRow>
                    <IonCol size="12">
                        <IonItem className={"ion-item-see-elements"} color={'success'} button detail lines={'none'} disabled={isLoading}
                                 routerLink={location.pathname + routes.productionunit + '/' + vppProductionUnit.id}>
                            <IonLabel>{t('see_elements')}</IonLabel>
                        </IonItem>

                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    );
};

export default VppProductionUnit;