import {IonButton, IonCol, IonIcon, IonItem, IonRow} from "@ionic/react";
import {chevronBack, chevronForward, playBack, playForward} from "ionicons/icons";


type PaginationProps = {
    startCallback: () => void;
    backCallback: () => void;
    nextCallback: () => void;
    endCallback: () => void;
    page: number;
    total: number;
};

const Pagination = ({startCallback, backCallback, nextCallback, endCallback, page, total} : PaginationProps) => {

    return (
        <IonRow class="ion-justify-content-center">
            <IonCol className="ion-item-center" size="auto">
                <IonButton fill="clear" onClick={startCallback}>
                    <IonIcon slot="icon-only" icon={playBack}/>
                </IonButton>
            </IonCol>
            <IonCol className="ion-item-center" size="auto">
                <IonButton fill="clear" onClick={backCallback}>
                    <IonIcon slot="icon-only" icon={chevronBack}/>
                </IonButton>
            </IonCol>
            <IonCol className="ion-item-center"  size="auto">
                <IonItem lines="none">
                    {page} / {total}
                </IonItem>
            </IonCol>
            <IonCol className="ion-item-center" size="auto">
                <IonButton fill="clear" onClick={nextCallback}>
                    <IonIcon slot="icon-only" icon={chevronForward}/>
                </IonButton>
            </IonCol>
            <IonCol className="ion-item-center" size="auto">
                <IonButton fill="clear" onClick={endCallback}>
                    <IonIcon slot="icon-only" icon={playForward}/>
                </IonButton>
            </IonCol>
        </IonRow>
    );
};

export default Pagination;