import React from "react";
import {IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {VppProductionUnitType} from "../../../types/types";
import './VppProductionUnit.css'
import {settingsOutline} from "ionicons/icons";

const VppProductionUnitParameters = (props: VppProductionUnitType) => {
    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'windpower' });
    return (
        <IonGrid>
            <IonRow>
                <IonItem lines={"full"}>
                    <IonLabel>
                        <b>{t('parameters')}</b>
                    </IonLabel>
                    <IonIcon icon={settingsOutline} slot={"start"}/>
                </IonItem>
            </IonRow>
            <IonRow>
                <IonCol size="6">
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h3>{t('nominal_power')}</h3>
                            <p>{props.nominal_power}</p>
                        </IonLabel>
                    </IonItem>
                </IonCol>
                <IonCol size="6">
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h3>{t('nbr_elements')}</h3>
                            <p>{props.nbr_active_generation_units}</p>
                        </IonLabel>
                    </IonItem>
                </IonCol>
            </IonRow>
        </IonGrid>

    );
};

export default VppProductionUnitParameters;