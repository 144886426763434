import React, {useState} from "react";
import {IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow, IonSkeletonText} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {calendarClearOutline, closeCircleSharp, unlinkOutline} from "ionicons/icons";
import DateSelector from "../../home/DateSelector";
import {addDays, formatDate, formatDatetimeQuery} from "../../../utils/utils";
import {
    useAddGenUnitUnavailabilityMutation,
    useDelGenUnitUnavailabilityMutation,
    useGetListGenUnitUnavailabilityQuery
} from "../../../services/vpp-api";
import {GenUnitUnavailabilityType} from "../../../types/types";
import {DatetimePresentation} from "@ionic/core/dist/types/components/datetime/datetime-interface";

const UnavailabilityGenerationUnit = ({id}: {id: number}) => {
    const { t, i18n } = useTranslation(['translation', 'common'], { keyPrefix: 'windpower.generation_unit' });
    const { data, isLoading } = useGetListGenUnitUnavailabilityQuery({generation_unit_fk:id})
    const [
        deleteGenUnav, // This is the mutation trigger
        { isLoading: isDeleting }, // This is the destructured mutation result
    ] = useDelGenUnitUnavailabilityMutation()

    const [
        addGenUnav, // This is the mutation trigger
    ] = useAddGenUnitUnavailabilityMutation()

    let skeletonUnav = {
        generation_unit_fk : id,
        start_datetime : undefined,
        end_datetime : undefined,
        power_available : 0
    }

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 15));
    return (
        <IonGrid>
            <IonRow>
                <IonItem lines={"full"}>
                    <IonLabel color={"dark"}>
                        <b>{t('add_unavailability')}</b>
                    </IonLabel>
                    <IonIcon icon={calendarClearOutline} slot={"start"}/>
                </IonItem>
            </IonRow>
            <DateSelector presentation={"date-time" as DatetimePresentation} unique_key={id} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate}/>
            <IonRow>
                <IonCol size="12">
                    <IonButton expand="block" onClick={()=>addGenUnav({...skeletonUnav,
                        start_datetime:formatDatetimeQuery(startDate, false, "isoDatetime"),
                        end_datetime:formatDatetimeQuery(endDate, false, "isoDatetime")})}>
                        {t('add')}
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol class="ion-text-center ion-text-uppercase" size="12">
                    <IonList>
                        <IonItem lines={"full"}>
                            <IonLabel color={"dark"}>
                                <b>{t('list_unavailability')}</b>
                            </IonLabel>
                            <IonIcon icon={unlinkOutline} slot={"start"}/>
                        </IonItem>
                        {isLoading || isDeleting ?
                            <IonItem>
                                <IonSkeletonText />
                            </IonItem> :
                            data?.map((value:GenUnitUnavailabilityType, idx)=>{
                                return (
                                    <IonItem key={value.id}>
                                        <IonLabel><small>{formatDate(value.start_datetime, i18n, true)} - {formatDate(value.end_datetime, i18n, true)}</small></IonLabel>
                                        <IonButton onClick={()=>deleteGenUnav(value.id)} slot={"end"} fill={"clear"} icon-only>
                                            <IonIcon color="danger" icon={closeCircleSharp}/>
                                        </IonButton>
                                    </IonItem>
                                )
                            })

                        }

                    </IonList>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default UnavailabilityGenerationUnit;