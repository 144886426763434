import {IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useGetPowerAvailabilityQuery, useGetPowerGenerationQuery} from "../../services/data-api";
import SimpleGraphicDateSelector from "../../components/home/SimpleGraphicDateSelector";
import {formatDatetimeQuery} from "../../utils/utils";


const Nuclear: React.FC = () => {
    const { t } = useTranslation(['translation', 'common'], { keyPrefix: 'nuclear' });

    const GRAPHICS = [
        {
            label:t("nuclear"),
            unique_key: "nuclear_power",
            fc_queries:[useGetPowerAvailabilityQuery, useGetPowerGenerationQuery],
            custom_filter:[
                {'creation_date__in': [formatDatetimeQuery(new Date(), true, "isoDate")],
                'production_type': 'NUCLEAR', 'country': 'france'},
                {'production_type': 'NUCLEAR', 'country': 'france'}],
            tooltip_add: 'unavailabilities',
            data_names:['valeur', 'valeur'],
            line_type: 'stepAfter',
            names:['', t("curve_name")],
            connect_nulls:true,
            active_multi_date_selector: [true, false],
            colors:['#540D6E', '#000000']
        },
        {
            label:t("fossil_gas"),
            unique_key: "fossil_power",
            fc_queries:[useGetPowerAvailabilityQuery, useGetPowerGenerationQuery],
            custom_filter:[
                {'creation_date__in': [formatDatetimeQuery(new Date(), true, "isoDate")],
                'production_type': 'FOSSIL_GAS', 'country': 'france'},
                {'production_type': 'FOSSIL_GAS', 'country': 'france'}],
            tooltip_add: 'unavailabilities',
            data_names:['valeur', 'valeur'],
            line_type: 'stepAfter',
            names:['', t("curve_name")],
            connect_nulls:true,
            active_multi_date_selector: [true, false],
            colors:['#540D6E', '#000000']
        },
        {
            label:t("solar"),
            unique_key: "solar_power",
            fc_queries:[useGetPowerAvailabilityQuery, useGetPowerGenerationQuery],
            custom_filter:[
                {'creation_date__in': [formatDatetimeQuery(new Date(), true, "isoDate")],
                'production_type': 'SOLAR', 'country': 'france'},
                {'production_type': 'SOLAR', 'country': 'france'}],
            tooltip_add: 'unavailabilities',
            data_names:['valeur', 'valeur'],
            line_type: 'stepAfter',
            names:['', t("curve_name")],
            connect_nulls:false,
            active_multi_date_selector: [false, false],
            colors:['#540D6E', '#000000']
        },
    ]

    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        {GRAPHICS.map((props, idx)=>{
                            return <SimpleGraphicDateSelector key={idx} {...props}/>
                        })}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>

    );
};


export default Nuclear;
